/**
 * @generated SignedSource<<c3a9cff41529cd8054856db9226c293d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type messagesInsertInput = {
  account_id?: string | null;
  content?: string | null;
  created_at?: string | null;
  group_id?: string | null;
  parent_message_id?: string | null;
  updated_at?: string | null;
};
export type insertMessageMutation$variables = {
  input: ReadonlyArray<messagesInsertInput>;
};
export type insertMessageMutation$data = {
  readonly insertIntomessagesCollection: {
    readonly records: ReadonlyArray<{
      readonly account_id: string;
      readonly content: string;
      readonly created_at: string;
      readonly group_id: string;
      readonly id: string;
      readonly nodeId: string;
    }>;
  } | null;
};
export type insertMessageMutation = {
  response: insertMessageMutation$data;
  variables: insertMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "input"
      }
    ],
    "concreteType": "messagesInsertResponse",
    "kind": "LinkedField",
    "name": "insertIntomessagesCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "messages",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "group_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "account_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertMessageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertMessageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a7dcb06648b1264118d09d7e7e374641",
    "id": null,
    "metadata": {},
    "name": "insertMessageMutation",
    "operationKind": "mutation",
    "text": "mutation insertMessageMutation(\n  $input: [messagesInsertInput!]!\n) {\n  insertIntomessagesCollection(objects: $input) {\n    records {\n      id\n      nodeId\n      created_at\n      group_id\n      account_id\n      content\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58a854158dcc106a8b09fd80c9b68d19";

export default node;
