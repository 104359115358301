/**
 * @generated SignedSource<<3ed1859230ca830c7226c5452dd48dc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type accounts_groupsUpdateInput = {
  account_id?: string | null;
  created_at?: string | null;
  execute?: boolean | null;
  group_id?: string | null;
  last_read_at?: string | null;
  muted?: boolean | null;
  read?: boolean | null;
  updated_at?: string | null;
  write?: boolean | null;
};
export type accounts_groupsFilter = {
  account_id?: BigIntFilter | null;
  and?: ReadonlyArray<accounts_groupsFilter> | null;
  created_at?: DatetimeFilter | null;
  execute?: BooleanFilter | null;
  group_id?: BigIntFilter | null;
  id?: BigIntFilter | null;
  last_read_at?: DatetimeFilter | null;
  muted?: BooleanFilter | null;
  nodeId?: IDFilter | null;
  not?: accounts_groupsFilter | null;
  or?: ReadonlyArray<accounts_groupsFilter> | null;
  read?: BooleanFilter | null;
  updated_at?: DatetimeFilter | null;
  write?: BooleanFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type BooleanFilter = {
  eq?: boolean | null;
  is?: FilterIs | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateAccountsGroupsMutation$variables = {
  filter: accounts_groupsFilter;
  input: accounts_groupsUpdateInput;
};
export type updateAccountsGroupsMutation$data = {
  readonly updateaccounts_groupsCollection: {
    readonly records: ReadonlyArray<{
      readonly execute: boolean;
      readonly id: string;
      readonly last_read_at: string | null;
      readonly muted: boolean;
      readonly nodeId: string;
      readonly read: boolean;
      readonly write: boolean;
    }>;
  };
};
export type updateAccountsGroupsMutation = {
  response: updateAccountsGroupsMutation$data;
  variables: updateAccountsGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "set",
        "variableName": "input"
      }
    ],
    "concreteType": "accounts_groupsUpdateResponse",
    "kind": "LinkedField",
    "name": "updateaccounts_groupsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts_groups",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last_read_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "read",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "write",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "execute",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "muted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateAccountsGroupsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateAccountsGroupsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0ced6195ed844e3d96cc70ddf17884a4",
    "id": null,
    "metadata": {},
    "name": "updateAccountsGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation updateAccountsGroupsMutation(\n  $input: accounts_groupsUpdateInput!\n  $filter: accounts_groupsFilter!\n) {\n  updateaccounts_groupsCollection(set: $input, filter: $filter) {\n    records {\n      nodeId\n      id\n      last_read_at\n      read\n      write\n      execute\n      muted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d8ffe9448a1dc3fc6442d3e0859d6e9";

export default node;
