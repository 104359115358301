import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/GroupSettings/GroupSettingsDialog.module.scss';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import GroupSettings from './GroupSettings';

export const GroupSettingsDialog = ({
  trigger,
  defaultOpen,
  onClose,
  groupId,
}: {
  trigger: React.ReactNode;
  defaultOpen?: boolean;
  onClose?: () => void;
  groupId: string;
}) => {
  return (
    <div>
      <BottomSheet
        defaultOpen={defaultOpen}
        dialogOnDesktop
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={(isPopState?: boolean) => {
          onClose?.();
        }}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense fallback={<></>}>
              <GroupSettings
                className={styles.modal}
                closeDialog={() => {
                  closeDialog();
                  onClose?.();
                }}
                groupId={groupId}
              />
            </Suspense>
          ),
          [onClose, groupId]
        )}
      </BottomSheet>
    </div>
  );
};
