import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeAccountFromGroupMutation($filter: accounts_groupsFilter!) {
    deleteFromaccounts_groupsCollection(filter: $filter) {
      records {
        id
        nodeId
      }
    }
  }
`;
