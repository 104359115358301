/**
 * @generated SignedSource<<a3f101ce4ee31c1dcf89fe90aefa1041>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyInboxContentGetMessagesPaginated$data = {
  readonly messagesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly account_id: string;
        readonly content: string;
        readonly created_at: string;
        readonly id: string;
        readonly messages: {
          readonly content: string;
          readonly id: string;
          readonly nodeId: string;
        } | null;
        readonly messages_reactionsCollection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly accounts: {
                readonly id: string;
                readonly identities: {
                  readonly id: string;
                  readonly nodeId: string;
                  readonly profilesCollection: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly id: string;
                        readonly nodeId: string;
                        readonly username: string | null;
                      };
                    }>;
                  } | null;
                };
              };
              readonly id: string;
              readonly nodeId: string;
              readonly reaction: string;
            };
          }>;
        } | null;
        readonly nodeId: string;
      };
    }>;
  } | null;
  readonly " $fragmentType": "MyInboxContentGetMessagesPaginated";
};
export type MyInboxContentGetMessagesPaginated$key = {
  readonly " $data"?: MyInboxContentGetMessagesPaginated$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyInboxContentGetMessagesPaginated">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "messagesCollection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./MyInboxContentGetMessagesPaginatedQuery.graphql')
    }
  },
  "name": "MyInboxContentGetMessagesPaginated",
  "selections": [
    {
      "alias": "messagesCollection",
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "eq",
                  "variableName": "groupId"
                }
              ],
              "kind": "ObjectValue",
              "name": "group_id"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "created_at": "DescNullsLast"
          }
        }
      ],
      "concreteType": "messagesConnection",
      "kind": "LinkedField",
      "name": "__MyInboxContentGetMessagesPaginated_messagesCollection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "messagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "messages",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "account_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "messages",
                  "kind": "LinkedField",
                  "name": "messages",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "messages_reactionsConnection",
                  "kind": "LinkedField",
                  "name": "messages_reactionsCollection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "messages_reactionsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "messages_reactions",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "reaction",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "accounts",
                              "kind": "LinkedField",
                              "name": "accounts",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "identities",
                                  "kind": "LinkedField",
                                  "name": "identities",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v2/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "profilesConnection",
                                      "kind": "LinkedField",
                                      "name": "profilesCollection",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "profilesEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "profiles",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v1/*: any*/),
                                                (v2/*: any*/),
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "username",
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cc369481bdb8bc52b66765289a048521";

export default node;
