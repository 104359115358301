/**
 * @generated SignedSource<<98ba7bc0d3ce798ec2dcd8bd941524b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type enum_notification_type = "add_comment_reaction" | "add_reaction" | "add_token_to_playlist" | "collect_token" | "comment" | "comment_mention" | "comment_reply" | "follow" | "list_token_for_sale" | "mention" | "%future added value";
export type notificationsUpdateInput = {
  comment_id?: string | null;
  created_at?: string | null;
  playlist_id?: string | null;
  price?: string | null;
  reaction?: string | null;
  read?: boolean | null;
  receiver_id?: string | null;
  sender_id?: string | null;
  tezos_token_id?: string | null;
  type?: enum_notification_type | null;
  updated_at?: string | null;
};
export type notificationsFilter = {
  and?: ReadonlyArray<notificationsFilter> | null;
  comment_id?: BigIntFilter | null;
  created_at?: DatetimeFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: notificationsFilter | null;
  or?: ReadonlyArray<notificationsFilter> | null;
  playlist_id?: BigIntFilter | null;
  price?: BigIntFilter | null;
  reaction?: StringFilter | null;
  read?: BooleanFilter | null;
  receiver_id?: BigIntFilter | null;
  sender_id?: BigIntFilter | null;
  tezos_token_id?: BigIntFilter | null;
  type?: enum_notification_typeFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type enum_notification_typeFilter = {
  eq?: enum_notification_type | null;
  in?: ReadonlyArray<enum_notification_type> | null;
  is?: FilterIs | null;
  neq?: enum_notification_type | null;
};
export type BooleanFilter = {
  eq?: boolean | null;
  is?: FilterIs | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateNotificationMutation$variables = {
  filter: notificationsFilter;
  input: notificationsUpdateInput;
};
export type updateNotificationMutation$data = {
  readonly updatenotificationsCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
      readonly read: boolean;
    }>;
  };
};
export type updateNotificationMutation = {
  response: updateNotificationMutation$data;
  variables: updateNotificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "set",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "notificationsUpdateResponse",
        "kind": "LinkedField",
        "name": "updatenotificationsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "notifications",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "notificationsUpdateResponse",
        "kind": "LinkedField",
        "name": "updatenotificationsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "notifications",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "64207c3a803b750464b5a687a88fa63e",
    "id": null,
    "metadata": {},
    "name": "updateNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation updateNotificationMutation(\n  $input: notificationsUpdateInput!\n  $filter: notificationsFilter!\n) {\n  updatenotificationsCollection(set: $input, filter: $filter) {\n    records {\n      id\n      read\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "698e2eba85f28a9ff695fec128202115";

export default node;
