import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertMessageMutation($input: [messagesInsertInput!]!) {
    insertIntomessagesCollection(objects: $input) {
      records {
        id
        nodeId
        created_at
        group_id
        account_id
        content
      }
    }
  }
`;
