/**
 * @generated SignedSource<<7763b55abb0de4c4e469016310034ab9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateChannelGetGroupsQuery$variables = {
  accountId: string;
};
export type CreateChannelGetGroupsQuery$data = {
  readonly accounts_groupsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly groups: {
          readonly id: string;
          readonly nodeId: string;
        };
      };
    }>;
  } | null;
};
export type CreateChannelGetGroupsQuery = {
  response: CreateChannelGetGroupsQuery$data;
  variables: CreateChannelGetGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "accountId"
          }
        ],
        "kind": "ObjectValue",
        "name": "account_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "groups",
  "kind": "LinkedField",
  "name": "groups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateChannelGetGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "accounts_groupsConnection",
        "kind": "LinkedField",
        "name": "accounts_groupsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts_groupsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts_groups",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateChannelGetGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "accounts_groupsConnection",
        "kind": "LinkedField",
        "name": "accounts_groupsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts_groupsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts_groups",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e7b6c16af8540316ea4b9f2c0427d27",
    "id": null,
    "metadata": {},
    "name": "CreateChannelGetGroupsQuery",
    "operationKind": "query",
    "text": "query CreateChannelGetGroupsQuery(\n  $accountId: BigInt!\n) {\n  accounts_groupsCollection(filter: {account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        groups {\n          id\n          nodeId\n        }\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95b7358128179e6cc078fc89dd6226b3";

export default node;
