/**
 * @generated SignedSource<<aee83ce4a7f234761a2159a87d045fa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type messagesFilter = {
  account_id?: BigIntFilter | null;
  and?: ReadonlyArray<messagesFilter> | null;
  content?: StringFilter | null;
  created_at?: DatetimeFilter | null;
  group_id?: BigIntFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: messagesFilter | null;
  or?: ReadonlyArray<messagesFilter> | null;
  parent_message_id?: BigIntFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type removeMessageMutation$variables = {
  filter: messagesFilter;
};
export type removeMessageMutation$data = {
  readonly deleteFrommessagesCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
      readonly nodeId: string;
    }>;
  };
};
export type removeMessageMutation = {
  response: removeMessageMutation$data;
  variables: removeMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "messagesDeleteResponse",
    "kind": "LinkedField",
    "name": "deleteFrommessagesCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "messages",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeMessageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeMessageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6270faf19aa7d47f16bd4bd8083c4a25",
    "id": null,
    "metadata": {},
    "name": "removeMessageMutation",
    "operationKind": "mutation",
    "text": "mutation removeMessageMutation(\n  $filter: messagesFilter!\n) {\n  deleteFrommessagesCollection(filter: $filter) {\n    records {\n      id\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a21b84c94b24972f5c08f1a3c26bd14";

export default node;
