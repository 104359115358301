import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertAccountsInGroupMutation(
    $input: [accounts_groupsInsertInput!]!
  ) {
    insertIntoaccounts_groupsCollection(objects: $input) {
      records {
        id
        nodeId
        group_id
        accounts {
          id
        }
        read
        write
        execute
        muted
      }
    }
  }
`;
