/**
 * @generated SignedSource<<27382a68c3ebf19d3ff75b0063ccea93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GroupSettingsGetGroupQuery$variables = {
  groupId: string;
};
export type GroupSettingsGetGroupQuery$data = {
  readonly groupsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly is_channel: boolean;
        readonly is_private: boolean;
        readonly nodeId: string;
        readonly title: string | null;
        readonly totalCount: {
          readonly totalCount: number;
        } | null;
      };
    }>;
  } | null;
};
export type GroupSettingsGetGroupQuery = {
  response: GroupSettingsGetGroupQuery$data;
  variables: GroupSettingsGetGroupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "groupId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "groupsConnection",
    "kind": "LinkedField",
    "name": "groupsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "groupsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "groups",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_channel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_private",
                "storageKey": null
              },
              {
                "alias": "totalCount",
                "args": null,
                "concreteType": "accounts_groupsConnection",
                "kind": "LinkedField",
                "name": "accounts_groupsCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupSettingsGetGroupQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupSettingsGetGroupQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4bc57779b304908f8ebf9b7560f6b173",
    "id": null,
    "metadata": {},
    "name": "GroupSettingsGetGroupQuery",
    "operationKind": "query",
    "text": "query GroupSettingsGetGroupQuery(\n  $groupId: BigInt!\n) {\n  groupsCollection(filter: {id: {eq: $groupId}}) {\n    edges {\n      node {\n        id\n        nodeId\n        title\n        is_channel\n        is_private\n        totalCount: accounts_groupsCollection {\n          totalCount\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "70ca6172c6eaf5b607ba8b896d5d95e2";

export default node;
