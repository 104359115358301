import {RealtimeChannel} from '@supabase/supabase-js';
import {supabase} from '@/kits/supabase-kit/src/index';

const client = supabase;

type SupabaseEventType = 'INSERT' | 'UPDATE' | 'DELETE' | '*';

export function createChannel(channel: string) {
  const realtimeChannel = client.channel(channel);
  return realtimeChannel;
}

export const subscribe = ({
  channel,
  event,
  table,
  schema = 'public',
  filter,
  callback,
}: {
  channel: string;
  event?: SupabaseEventType;
  table?: string;
  schema?: string;
  filter?: string;
  callback?: (payload: any) => void;
}) => {
  const realtimeChannel: RealtimeChannel = createChannel(channel);
  realtimeChannel
    .on(
      'postgres_changes',
      {
        event: event ? (event as any) : '*',
        table: table,
        schema: schema,
        filter: filter,
      },
      callback !== undefined ? callback : (payload: any) => {}
    )
    .subscribe();
  return realtimeChannel;
};

export const unsubscribe = (realtimeChannel: RealtimeChannel) => {
  realtimeChannel.unsubscribe();
};

export const unsubscribeAll = () => {
  client.realtime.channels.forEach(channel => {
    channel.unsubscribe();
  });
};

export const closeConnection = (channel: RealtimeChannel) => {
  client.removeChannel(channel);
};

export const closeAllConnections = () => {
  client.removeAllChannels();
};

export const getActiveChannels = () => {
  return client.realtime.channels;
};

type ComparatorType = 'eq' | 'neq' | 'lt' | 'lte' | 'gt' | 'gte' | 'in';

type GenerateFilterProps = {
  column_name: string;
} & (
  | {
      comparator: 'in';
      value: (string | number | boolean | undefined)[];
    }
  | {
      comparator: Exclude<ComparatorType, 'in'>;
      value: string | number | boolean | undefined;
    }
);

export const generateFilter = ({
  column_name,
  comparator,
  value,
}: GenerateFilterProps) => {
  if (comparator === 'in') {
    return `${column_name}=${comparator}.(${value.join(',')})`;
  } else {
    return `${column_name}=${comparator}.${value as string}`;
  }
};
