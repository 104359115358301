/**
 * @generated SignedSource<<8d06dac8ac2d6f50f4b4f61f0ea6f428>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_notification_type = "add_comment_reaction" | "add_reaction" | "add_token_to_playlist" | "collect_token" | "comment" | "comment_mention" | "comment_reply" | "follow" | "list_token_for_sale" | "mention" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationItemFragment$data = {
  readonly comments: {
    readonly content: string | null;
  } | null;
  readonly created_at: string;
  readonly id: string;
  readonly playlists: {
    readonly title: string;
  } | null;
  readonly price: string | null;
  readonly reaction: string | null;
  readonly sender: {
    readonly identities: {
      readonly id: string;
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly username: string | null;
          };
        }>;
      } | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"UserProfileItemFragment">;
  } | null;
  readonly tezos_tokens: {
    readonly id: string;
    readonly thumbnail_uri: string;
    readonly title: string;
  } | null;
  readonly type: enum_notification_type;
  readonly " $fragmentType": "NotificationItemFragment";
};
export type NotificationItemFragment$key = {
  readonly " $data"?: NotificationItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "sender",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileItemFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "comments",
      "kind": "LinkedField",
      "name": "comments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "tezos_tokens",
      "kind": "LinkedField",
      "name": "tezos_tokens",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail_uri",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "playlists",
      "kind": "LinkedField",
      "name": "playlists",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reaction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    }
  ],
  "type": "notifications",
  "abstractKey": null
};
})();

(node as any).hash = "808ce35a75e4613031823127eb39ef21";

export default node;
