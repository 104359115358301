import React, {Suspense} from 'react';
import styles from '@/view/styles/components/TopBar/DefaultTopBar.module.scss';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import {useAccount} from '@/kits/account-kit/src';
import Router, {useRouter} from 'next/router';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {ShoppingCartDialog} from '../ShoppingCart/ShoppingCartDialog';
import {useNotifications} from '@/state/hooks/notifications';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import Menu from '../Menu';
import InfiniteLoader from '../InfiniteLoader';
import {NotificationsWindowBody} from '../Inbox/MyInboxContent';
import Image from '../Image';
import {ItemModalActions} from '@/state/hooks/itemModal';
import {getMediaLink} from '@/state/hooks/mediaPlaylistFunctions';

export default function DefaultTopBar() {
  const {getAccount} = useAccount();
  const user = getAccount();

  const showDrawer = () => {
    const root = document.documentElement;
    root.style.setProperty('--mobileDrawerVisibility', 'visible');
    root.style.setProperty('--mobileDrawerTranslate', '0');
    root.style.setProperty('--mobileDrawerOpacity', '1');
  };

  const router = useRouter();
  const {unreadCount} = useNotifications();

  const {content} = useShoppingCart();
  const {actions} = useOnchainActionStatus();
  const {isMobile} = useBreakpoint();
  const buyInProgress =
    actions.some(a => a.status === 'pending') && content.length !== 0;

  const [showTopBanner, setShowTopBanner] = React.useState(false);

  React.useEffect(() => {
    // TODO: enable when we launch the beta
    const topBanner = localStorage.getItem('topBannerv4');
    if (topBanner === 'false') {
      setShowTopBanner(false);
    } else {
      setShowTopBanner(true);
    }
  }, []);

  return (
    <>
      {showTopBanner && isMobile && (
        <div className={styles.info}>
          <div>
            <span>
              DNS is in public beta! We are experiencing a ton of users, and
              working on scalability issues.
            </span>
            <span>
              Most are fixed. Please click Retry if there are issues, and reach
              us on Discord for help.
            </span>
          </div>
          <Button
            icon
            onClick={() => {
              setShowTopBanner(false);
              localStorage.setItem('topBannerv4', 'false');
            }}
          >
            <RemixIcon icon="close-line" size={16} />
          </Button>
        </div>
      )}
      <div
        className={styles.topBar}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        data-has-top-banner={showTopBanner}
      >
        <div>
          <Button
            icon
            onClick={() => {
              showDrawer();
            }}
          >
            <RemixIcon icon="menu-2-fill" size={24} />
          </Button>
          <div className={styles.spacer} />
          <Image src="/DNSIcon.svg" alt="DNS" className={styles.dnsIcon} />
          <ShoppingCartDialog
            trigger={
              <Button
                icon
                className={styles.withUnreadCount}
                onClick={() => {
                  Router.push(
                    {pathname: router.pathname, query: {...router.query}},
                    router.asPath + '#',
                    {
                      shallow: true,
                      scroll: false,
                    }
                  );
                }}
                disabled={buyInProgress}
              >
                <RemixIcon icon="shopping-cart-2-line" size={24} />
                {content.length > 0 && (
                  <span>
                    {buyInProgress ? (
                      <RemixIcon
                        icon="loader-4-fill"
                        size={16}
                        className={styles.spin}
                      />
                    ) : (
                      <span>
                        {content.reduce((acc, item) => acc + item.quantity, 0)}
                      </span>
                    )}
                  </span>
                )}
              </Button>
            }
          />
          {user.isLoggedIn && (
            <Button icon>
              <Menu
                element={close => {
                  return (
                    <Suspense fallback={<InfiniteLoader height={'70vh'} />}>
                      <NotificationsWindowBody
                        setPage={() => {}}
                        close={link => {
                          if (link && link.startsWith(`/item/`)) {
                            const tokenId = link.split(`/item/`)[1];
                            router
                              .push(
                                {
                                  pathname: router.pathname,
                                  query: {...router.query},
                                },
                                getMediaLink(tokenId, undefined),
                                {shallow: true}
                              )
                              .then(() => {
                                ItemModalActions.setItem(true, tokenId);
                              });
                          } else if (link) {
                            router.push(link);
                          }
                          close?.();
                        }}
                      />
                    </Suspense>
                  );
                }}
                className={styles.notificationsMenu}
                asModal
              >
                <span className={styles.withUnreadCount}>
                  <RemixIcon
                    icon={`notification-3-${!unreadCount ? 'line' : 'fill'}`}
                    size={24}
                  />
                  {unreadCount > 0 && (
                    <span>
                      <span>{unreadCount > 99 ? '99+' : unreadCount}</span>
                    </span>
                  )}
                </span>
              </Menu>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
