/**
 * @generated SignedSource<<f286d5c4464cbae7398e22e9baa85771>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MessagesUserInfoFollowingQuery$variables = {
  myIdentityId: string;
  theirIdentityId: string;
};
export type MessagesUserInfoFollowingQuery$data = {
  readonly followsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
      };
    }>;
  } | null;
};
export type MessagesUserInfoFollowingQuery = {
  response: MessagesUserInfoFollowingQuery$data;
  variables: MessagesUserInfoFollowingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "myIdentityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "theirIdentityId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "theirIdentityId"
              }
            ],
            "kind": "ObjectValue",
            "name": "followee_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "myIdentityId"
              }
            ],
            "kind": "ObjectValue",
            "name": "follower_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "followsConnection",
    "kind": "LinkedField",
    "name": "followsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "followsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "follows",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessagesUserInfoFollowingQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessagesUserInfoFollowingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "844e165c7f388f6249739dab93e17183",
    "id": null,
    "metadata": {},
    "name": "MessagesUserInfoFollowingQuery",
    "operationKind": "query",
    "text": "query MessagesUserInfoFollowingQuery(\n  $myIdentityId: BigInt!\n  $theirIdentityId: BigInt!\n) {\n  followsCollection(filter: {follower_id: {eq: $myIdentityId}, followee_id: {eq: $theirIdentityId}}) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ae691b45bf8b7b7808c316d1faf5d83";

export default node;
