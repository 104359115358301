import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeMessageMutation($filter: messagesFilter!) {
    deleteFrommessagesCollection(filter: $filter) {
      records {
        id
        nodeId
      }
    }
  }
`;
