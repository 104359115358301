import React from 'react';
import styles from '@/view/styles/components/GroupSettings/GroupSettings.module.scss';
import clsx from 'clsx';
import Input from '../Input/Input';
import {useAccount} from '@/kits/account-kit/src';
import RemixIcon from '../RemixIcon';
import Switch from '../Switch';
import {graphql, useLazyLoadQuery, useMutation} from '@/kits/relay-kit/src';
import updateGroupMutation from '@/graphql/update-group';
import type {updateGroupMutation as UpdateGroupMutationType} from '@/graphql/__generated__/updateGroupMutation.graphql';
import removeGroupMutation from '@/graphql/remove-group';
import type {removeGroupMutation as RemoveGroupMutationType} from '@/graphql/__generated__/removeGroupMutation.graphql';
import updateAccountsGroupMutation from '@/graphql/update-accounts-groups';
import type {updateAccountsGroupsMutation as UpdateAccountsGroupMutationType} from '@/graphql/__generated__/updateAccountsGroupsMutation.graphql';
import removeAccountsGroupMutation from '@/graphql/remove-account-from-group';
import type {removeAccountFromGroupMutation as RemoveAccountsGroupMutationType} from '@/graphql/__generated__/removeAccountFromGroupMutation.graphql';
import {ToastActions} from '@/state/hooks/toasts';
import Button from '../Button';
import Router from 'next/router';
import type {GroupSettingsGetGroupQuery as GroupSettingsGetGroupQueryType} from './__generated__/GroupSettingsGetGroupQuery.graphql';
import type {GroupSettingsGetGroupMemberQuery as GroupSettingsGetGroupMemberQueryType} from './__generated__/GroupSettingsGetGroupMemberQuery.graphql';

const GroupSettingsGetGroupQuery = graphql`
  query GroupSettingsGetGroupQuery($groupId: BigInt!) {
    groupsCollection(filter: {id: {eq: $groupId}}) {
      edges {
        node {
          id
          nodeId
          title
          is_channel
          is_private
          totalCount: accounts_groupsCollection {
            totalCount
          }
        }
      }
    }
  }
`;

const GroupSettingsGetGroupMemberQuery = graphql`
  query GroupSettingsGetGroupMemberQuery(
    $groupId: BigInt!
    $accountId: BigInt!
  ) {
    accounts_groupsCollection(
      filter: {
        and: [{group_id: {eq: $groupId}}, {account_id: {eq: $accountId}}]
      }
    ) {
      edges {
        node {
          muted
          read
          write
          execute
        }
      }
    }
  }
`;

export default function GroupSettings({
  className,
  closeDialog,
  groupId,
}: {
  className?: string;
  closeDialog: () => void;
  groupId: string;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {data, retry: refetchGroupDetails} =
    useLazyLoadQuery<GroupSettingsGetGroupQueryType>(
      GroupSettingsGetGroupQuery,
      {
        groupId: groupId,
      },
      {
        skip: !groupId || groupId === '',
      }
    );

  const {data: mySettings, retry} =
    useLazyLoadQuery<GroupSettingsGetGroupMemberQueryType>(
      GroupSettingsGetGroupMemberQuery,
      {
        groupId: groupId,
        accountId: accountId || '',
      },
      {
        skip: !accountId || accountId === '' || !groupId || groupId === '',
      }
    );

  const isGroup =
    data?.groupsCollection?.edges?.[0]?.node?.is_private === false;
  const isChannel = data?.groupsCollection?.edges?.[0]?.node?.is_channel;

  const isConversationMuted =
    mySettings?.accounts_groupsCollection?.edges?.[0]?.node?.muted || false;

  const isAdmin =
    mySettings?.accounts_groupsCollection?.edges?.[0]?.node?.execute;

  const [error, setError] = React.useState<string | null>(null);

  const [updateGroup] =
    useMutation<UpdateGroupMutationType>(updateGroupMutation);

  const [removeGroup] =
    useMutation<RemoveGroupMutationType>(removeGroupMutation);

  const [updateAccountsGroup] = useMutation<UpdateAccountsGroupMutationType>(
    updateAccountsGroupMutation
  );

  const [removeAccountsGroup] = useMutation<RemoveAccountsGroupMutationType>(
    removeAccountsGroupMutation
  );

  const [title, setTitle] = React.useState<string>(
    data?.groupsCollection?.edges?.[0]?.node?.title || ''
  );

  const groupMemberCount =
    data?.groupsCollection?.edges?.[0]?.node?.totalCount?.totalCount;

  return (
    <div className={clsx(styles.root, className)}>
      <div>
        <span>Settings</span>
      </div>
      {isAdmin && (isGroup || isChannel) && (
        <div className={styles.content}>
          <div className={styles.channelTitle}>Group Title</div>
          <div className={styles.horizontalGroup}>
            <Input
              type="text"
              simple
              fullWidth
              defaultValue={title}
              onChange={v => {
                setTitle(v);
              }}
              placeholder={`Enter a name for your ${
                isChannel ? 'channel' : 'group'
              }`}
            />
            <Button
              text
              onClick={() => {
                updateGroup({
                  variables: {
                    filter: {
                      id: {eq: groupId},
                    },
                    input: {
                      title: title,
                    },
                  },
                  onCompleted(response) {
                    ToastActions.addToast(
                      response.updategroupsCollection?.records?.[0]?.nodeId +
                        ' updated',
                      'Group updated',
                      '',
                      'success'
                    );
                    retry();
                  },
                  onError(error) {
                    ToastActions.addToast(
                      error.message || 'Error updating group',
                      'Error updating group',
                      '',
                      'failure'
                    );
                    retry();
                  },
                });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {isAdmin && (isGroup || isChannel) && (
        <div className={styles.content}>
          <div className={styles.channelTitle}>Type</div>
          <div className={styles.horizontalGroup}>
            <div className={styles.buttonGroup}>
              <Button
                filled
                accent
                onClick={() => {
                  updateGroup({
                    variables: {
                      filter: {
                        id: {eq: groupId},
                      },
                      input: {
                        is_channel: false,
                      },
                    },
                    onCompleted(response) {
                      ToastActions.addToast(
                        response.updategroupsCollection?.records?.[0]?.nodeId +
                          ' updated',
                        'This is now a group',
                        '',
                        'success'
                      );
                      retry();
                      refetchGroupDetails();
                    },
                    onError(error) {
                      ToastActions.addToast(
                        error.message || 'Error updating type',
                        'Error updating type',
                        '',
                        'failure'
                      );
                      retry();
                    },
                  });
                }}
                data-selected={
                  data?.groupsCollection?.edges?.[0]?.node?.is_private ===
                    false &&
                  data?.groupsCollection?.edges?.[0]?.node?.is_channel === false
                }
              >
                Group
              </Button>
              <Button
                filled
                accent
                onClick={() => {
                  updateGroup({
                    variables: {
                      filter: {
                        id: {eq: groupId},
                      },
                      input: {
                        is_channel: true,
                      },
                    },
                    onCompleted(response) {
                      ToastActions.addToast(
                        response.updategroupsCollection?.records?.[0]?.nodeId +
                          ' updated',
                        'This is now a channel',
                        '',
                        'success'
                      );
                      retry();
                      refetchGroupDetails();
                    },
                    onError(error) {
                      ToastActions.addToast(
                        error.message || 'Error updating type',
                        'Error updating type',
                        '',
                        'failure'
                      );
                      retry();
                    },
                  });
                }}
                data-selected={
                  data?.groupsCollection?.edges?.[0]?.node?.is_private ===
                    false &&
                  data?.groupsCollection?.edges?.[0]?.node?.is_channel === true
                }
              >
                Channel
              </Button>
            </div>
          </div>
          <span>
            {data?.groupsCollection?.edges?.[0]?.node?.is_private === false &&
            data?.groupsCollection?.edges?.[0]?.node?.is_channel === false
              ? `Anyone can send messages in a group. Groups can only be read if you're a member.`
              : `Only the admins can send messages in a channel. Channels are publicly readable.`}
          </span>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.horizontalGroup}>
          <span>
            Mute {isChannel ? 'channel' : isGroup ? 'group' : 'conversation'}
          </span>
          <Switch
            checked={isConversationMuted}
            setChecked={checked => {
              updateAccountsGroup({
                variables: {
                  filter: {
                    and: [
                      {
                        group_id: {eq: groupId},
                      },
                      {
                        account_id: {eq: accountId},
                      },
                    ],
                  },
                  input: {
                    muted: checked,
                  },
                },
                onCompleted(response) {
                  ToastActions.addToast(
                    response.updateaccounts_groupsCollection?.records?.[0]
                      ?.nodeId + ' updated',
                    'Conversation muted',
                    '',
                    'success'
                  );
                  retry();
                },
                onError(error) {
                  ToastActions.addToast(
                    error.message || 'Error muting conversation',
                    'Error muting conversation',
                    '',
                    'failure'
                  );
                  retry();
                },
              });
            }}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.channelTitle}>
          {groupMemberCount === 1
            ? 'Delete'
            : isChannel
            ? 'Leave channel'
            : isGroup
            ? 'Leave group'
            : 'Delete chat'}
        </div>
        <span className={styles.leaveGroupText}>
          {groupMemberCount === 1
            ? 'Deleting this group will remove it for all members. This action cannot be undone.'
            : isChannel
            ? `You will not receive any notifications from this channel unless you re-join the channel.`
            : isGroup
            ? `You will not receive any messages from this group unless a member re-invites you in the discussion. This action cannot be undone.`
            : 'Delete this conversation for you. This action cannot be undone.'}
        </span>
        <div>
          {groupMemberCount === 1 ? (
            <Button
              text
              onClick={() => {
                removeGroup({
                  variables: {
                    filter: {
                      id: {eq: groupId},
                    },
                  },
                  onCompleted(response) {
                    ToastActions.addToast(
                      response.deleteFromgroupsCollection?.records?.[0]
                        ?.nodeId + ' deleted',
                      'Group deleted',
                      '',
                      'success'
                    );
                    Router.replace('/inbox');
                    closeDialog();
                  },
                });
                closeDialog();
              }}
            >
              <span
                style={{
                  color: 'var(--error)',
                }}
              >
                Delete {isChannel ? 'channel' : isGroup ? 'group' : 'chat'}
              </span>
            </Button>
          ) : (
            <Button
              text
              onClick={() => {
                removeAccountsGroup({
                  variables: {
                    filter: {
                      and: [
                        {
                          group_id: {eq: groupId},
                        },
                        {
                          account_id: {eq: accountId},
                        },
                      ],
                    },
                  },
                  onCompleted(response) {
                    ToastActions.addToast(
                      response.deleteFromaccounts_groupsCollection?.records?.[0]
                        ?.nodeId + ' removed',
                      'Left conversation',
                      '',
                      'success'
                    );
                    closeDialog();
                  },
                });
                closeDialog();
              }}
            >
              <span
                style={{
                  color: 'var(--error)',
                }}
              >
                Leave {isChannel ? 'channel' : isGroup ? 'group' : 'chat'}
              </span>
            </Button>
          )}
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <RemixIcon icon="error-warning-line" size={16} />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}
