import React, {PropsWithChildren} from 'react';
import InfiniteLoader from '../InfiniteLoader';

export default function InfiniteScrollContainer({
  root,
  children,
  loadMore,
  styleOverrides,
}: PropsWithChildren<{
  root?: Element | Document | null | undefined;
  loadMore?: () => void;
  styleOverrides?: React.CSSProperties;
}>) {
  const refetchRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const current = refetchRef.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          loadMore?.();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );
    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [root, loadMore]);

  return (
    <>
      {children}
      {loadMore && (
        <div
          ref={refetchRef}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            width: '100%',
            ...styleOverrides,
          }}
          data-refetch-trigger="true"
        >
          <InfiniteLoader marginTop={'auto'} width={'100%'} height={'60vh'} />
        </div>
      )}
    </>
  );
}
