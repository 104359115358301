/**
 * @generated SignedSource<<9ffee190e27adc64f5846160e93dc23a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GroupSettingsGetGroupMemberQuery$variables = {
  accountId: string;
  groupId: string;
};
export type GroupSettingsGetGroupMemberQuery$data = {
  readonly accounts_groupsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly execute: boolean;
        readonly muted: boolean;
        readonly read: boolean;
        readonly write: boolean;
      };
    }>;
  } | null;
};
export type GroupSettingsGetGroupMemberQuery = {
  response: GroupSettingsGetGroupMemberQuery$data;
  variables: GroupSettingsGetGroupMemberQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v2 = [
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "groupId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "group_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.0"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "accountId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "account_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "and.1"
          }
        ],
        "kind": "ListValue",
        "name": "and"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "muted",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "write",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "execute",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupSettingsGetGroupMemberQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "accounts_groupsConnection",
        "kind": "LinkedField",
        "name": "accounts_groupsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts_groupsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts_groups",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GroupSettingsGetGroupMemberQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "accounts_groupsConnection",
        "kind": "LinkedField",
        "name": "accounts_groupsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts_groupsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts_groups",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9cfcba85b0cfa774d26a10ff0e9099a3",
    "id": null,
    "metadata": {},
    "name": "GroupSettingsGetGroupMemberQuery",
    "operationKind": "query",
    "text": "query GroupSettingsGetGroupMemberQuery(\n  $groupId: BigInt!\n  $accountId: BigInt!\n) {\n  accounts_groupsCollection(filter: {and: [{group_id: {eq: $groupId}}, {account_id: {eq: $accountId}}]}) {\n    edges {\n      node {\n        muted\n        read\n        write\n        execute\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8fc93ce5a1a5b411afe04a087511fb22";

export default node;
