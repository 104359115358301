import React from 'react';
import styles from '@/view/styles/components/CreateChannel/CreateChannel.module.scss';
import clsx from 'clsx';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import {useAccount} from '@/kits/account-kit/src';
import Input from '../Input/Input';
import {graphql, useLazyLoadQuery, useMutation} from '@/kits/relay-kit/src';
import insertGroupMutation from '@/graphql/insert-group';
import type {insertGroupMutation as InsertGroupMutationType} from '@/graphql/__generated__/insertGroupMutation.graphql';
import insertAccountsInGroupMutation from '@/graphql/insert-accounts-in-group';
import type {insertAccountsInGroupMutation as InsertAccountsInGroupMutationType} from '@/graphql/__generated__/insertAccountsInGroupMutation.graphql';
import Switch from '../Switch';
import type {CreateChannelGetGroupsQuery as CreateChannelGetGroupsQueryType} from './__generated__/CreateChannelGetGroupsQuery.graphql';

const CreateChannelGetGroupsQuery = graphql`
  query CreateChannelGetGroupsQuery($accountId: BigInt!) {
    accounts_groupsCollection(filter: {account_id: {eq: $accountId}}) {
      edges {
        node {
          groups {
            id
            nodeId
          }
        }
      }
    }
  }
`;

export default function CreateChannel({
  closeDialog,
  className,
}: {
  closeDialog: () => void;
  className?: string;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {retry: refetchGroups} =
    useLazyLoadQuery<CreateChannelGetGroupsQueryType>(
      CreateChannelGetGroupsQuery,
      {
        accountId: accountId || '',
      },
      {
        skip: !accountId || accountId === '',
      }
    );

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const [title, setTitle] = React.useState<string>('');
  const [willBeChannel, setWillBeChannel] = React.useState<boolean>(true);

  const [insertGroup] =
    useMutation<InsertGroupMutationType>(insertGroupMutation);
  const [insertAccountsInGroup] =
    useMutation<InsertAccountsInGroupMutationType>(
      insertAccountsInGroupMutation
    );

  return (
    <div className={clsx(styles.root, className)}>
      <div>
        <span>Create new</span>
      </div>
      <div className={styles.content}>
        <div>
          <span>Title</span>
          <Input
            type="text"
            defaultValue={title}
            onChange={v => setTitle(v)}
            fullWidth
            placeholder="Enter a name for your channel"
          />
        </div>
        <div>
          <span>
            Make this a channel{' '}
            <span>
              (Channels are public and anyone can join. Only you and channel
              admins can post.)
            </span>
          </span>
          <Switch checked={willBeChannel} setChecked={setWillBeChannel} />
        </div>
        <div className={styles.actions}>
          <Button
            accent
            disabled={false}
            onClick={e => {
              e.preventDefault();
              if (!title) {
                setError('Please enter a title');
                return;
              }
              if (!accountId) {
                setError('Please login to create a channel');
                return;
              }
              setLoading(true);
              insertGroup({
                variables: {
                  input: [
                    {
                      created_at: 'now',
                      updated_at: 'now',
                      is_channel: willBeChannel,
                      is_private: false,
                      title,
                    },
                  ],
                },
                onCompleted(response) {
                  const group_id =
                    response.insertIntogroupsCollection?.records?.[0]?.id;
                  insertAccountsInGroup({
                    variables: {
                      input: [
                        {
                          group_id,
                          account_id: accountId,
                          read: true,
                          write: true,
                          execute: true,
                        },
                      ],
                    },
                    onCompleted() {
                      setLoading(false);
                      if (!group_id) return;
                      refetchGroups();
                      closeDialog();
                    },
                    onError(err) {
                      setLoading(false);
                      if (err.message) {
                        setError(err.message);
                      } else {
                        setError('An error occurred');
                      }
                    },
                  });
                },
                onError(err) {
                  if (err.message) {
                    setError(err.message);
                  } else {
                    setError('An error occurred');
                  }
                  setLoading(false);
                },
              });
            }}
          >
            <span>Create</span>
          </Button>
          {error && (
            <div className={styles.error}>
              <RemixIcon icon="error-warning-line" size={16} />
              <span>{error}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
