import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateNotificationMutation(
    $input: notificationsUpdateInput!
    $filter: notificationsFilter!
  ) {
    updatenotificationsCollection(set: $input, filter: $filter) {
      records {
        id
        read
      }
    }
  }
`;
