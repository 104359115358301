import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateGroupMutation(
    $input: groupsUpdateInput!
    $filter: groupsFilter!
  ) {
    updategroupsCollection(set: $input, filter: $filter) {
      records {
        nodeId
        id
        title
        description
        is_channel
        is_private
      }
    }
  }
`;
