import {createClient} from '@supabase/supabase-js';
import config from '../config.json';
import {useJWT} from '@/state/hooks/jwt';

const token = useJWT.getState().token;

export const supabase = createClient(config.supabaseUrl, config.supabaseKey);

if (token) {
  supabase.realtime.setAuth(token);
}
