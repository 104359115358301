import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertGroupMutation($input: [groupsInsertInput!]!) {
    insertIntogroupsCollection(objects: $input) {
      records {
        id
        nodeId
        title
        description
        is_channel
        is_private
      }
    }
  }
`;
