/**
 * @generated SignedSource<<1c8149354c04728c6e17b87c7603bbd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type accounts_groupsFilter = {
  account_id?: BigIntFilter | null;
  and?: ReadonlyArray<accounts_groupsFilter> | null;
  created_at?: DatetimeFilter | null;
  execute?: BooleanFilter | null;
  group_id?: BigIntFilter | null;
  id?: BigIntFilter | null;
  last_read_at?: DatetimeFilter | null;
  muted?: BooleanFilter | null;
  nodeId?: IDFilter | null;
  not?: accounts_groupsFilter | null;
  or?: ReadonlyArray<accounts_groupsFilter> | null;
  read?: BooleanFilter | null;
  updated_at?: DatetimeFilter | null;
  write?: BooleanFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type BooleanFilter = {
  eq?: boolean | null;
  is?: FilterIs | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type removeAccountFromGroupMutation$variables = {
  filter: accounts_groupsFilter;
};
export type removeAccountFromGroupMutation$data = {
  readonly deleteFromaccounts_groupsCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
      readonly nodeId: string;
    }>;
  };
};
export type removeAccountFromGroupMutation = {
  response: removeAccountFromGroupMutation$data;
  variables: removeAccountFromGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "accounts_groupsDeleteResponse",
    "kind": "LinkedField",
    "name": "deleteFromaccounts_groupsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts_groups",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeAccountFromGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeAccountFromGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba3d15b8d46168d3b40b543b1a82e6a9",
    "id": null,
    "metadata": {},
    "name": "removeAccountFromGroupMutation",
    "operationKind": "mutation",
    "text": "mutation removeAccountFromGroupMutation(\n  $filter: accounts_groupsFilter!\n) {\n  deleteFromaccounts_groupsCollection(filter: $filter) {\n    records {\n      id\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bee7c1d8360dec93bcb622289049637";

export default node;
