/**
 * @generated SignedSource<<b906e984c60ed698593ba250adf43b71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type accounts_groupsInsertInput = {
  account_id?: string | null;
  created_at?: string | null;
  execute?: boolean | null;
  group_id?: string | null;
  last_read_at?: string | null;
  muted?: boolean | null;
  read?: boolean | null;
  updated_at?: string | null;
  write?: boolean | null;
};
export type insertAccountsInGroupMutation$variables = {
  input: ReadonlyArray<accounts_groupsInsertInput>;
};
export type insertAccountsInGroupMutation$data = {
  readonly insertIntoaccounts_groupsCollection: {
    readonly records: ReadonlyArray<{
      readonly accounts: {
        readonly id: string;
      };
      readonly execute: boolean;
      readonly group_id: string;
      readonly id: string;
      readonly muted: boolean;
      readonly nodeId: string;
      readonly read: boolean;
      readonly write: boolean;
    }>;
  } | null;
};
export type insertAccountsInGroupMutation = {
  response: insertAccountsInGroupMutation$data;
  variables: insertAccountsInGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "group_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "write",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "execute",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "muted",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertAccountsInGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "accounts_groupsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoaccounts_groupsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts_groups",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertAccountsInGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "accounts_groupsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoaccounts_groupsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts_groups",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8c1dce28bb416dec476998aa234ec89",
    "id": null,
    "metadata": {},
    "name": "insertAccountsInGroupMutation",
    "operationKind": "mutation",
    "text": "mutation insertAccountsInGroupMutation(\n  $input: [accounts_groupsInsertInput!]!\n) {\n  insertIntoaccounts_groupsCollection(objects: $input) {\n    records {\n      id\n      nodeId\n      group_id\n      accounts {\n        id\n        nodeId\n      }\n      read\n      write\n      execute\n      muted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29578246ab5e4db9eb88530ba8fa1b9c";

export default node;
