import React, {Suspense} from 'react';
import styles from '@/view/styles/components/TopBar/DesktopTopBar.module.scss';
import RemixIcon from '../RemixIcon';
import Button from '../Button';
import BackButton from '../BackButton';
import clsx from 'clsx';
import {PAGES, SHOPPING_CART} from '@/config';
import {useRouter} from 'next/router';
import SearchDialog from '../CMDk/Search/SearchDialog';
import {useAccount} from '@/kits/account-kit/src';
import {ShoppingCartDialog} from '../ShoppingCart/ShoppingCartDialog';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {TezBalanceActions, useTezBalance} from '@/state/hooks/useTezBalance';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import Link from 'next/link';
import Tooltip from '../Tooltip';
import {useNotifications} from '@/state/hooks/notifications';
import Menu from '../Menu';
import {NotificationsWindowBody} from '../Inbox/MyInboxContent';
import InfiniteLoader from '../InfiniteLoader';
import {ItemModalActions} from '@/state/hooks/itemModal';
import {getMediaLink} from '@/state/hooks/mediaPlaylistFunctions';

export default function DesktopTopBar({
  title,
  className,
  leftActions,
  leftActionsOverride,
  rightActions,
  rightActionsOverride,
  preventBack = false,
}: {
  title?: React.ReactNode;
  className?: string;
  leftActions?: React.ReactNode[];
  leftActionsOverride?: boolean;
  rightActions?: React.ReactNode[];
  rightActionsOverride?: boolean;
  preventBack?: boolean;
}) {
  const router = useRouter();
  const {getAccount} = useAccount();
  const user = getAccount();
  const address = user?.isLoggedIn ? user.address : undefined;

  const {balance, loading} = useTezBalance();

  const {unreadCount} = useNotifications();
  const {content} = useShoppingCart();
  const {actions} = useOnchainActionStatus();
  const buyInProgress =
    actions.some(a => a.status === 'pending') && content.length !== 0;

  const [cartTooltip, setCartTooltip] = React.useState('View Cart');
  const [tooltipForceOpen, setTooltipForceOpen] =
    React.useState<boolean>(false);

  const cartCount = React.useRef(content.length || 0);

  React.useEffect(() => {
    if (buyInProgress) {
      setCartTooltip('Payment in progress');
    } else {
      setCartTooltip('View Cart');
    }
    if (content.length > cartCount.current) {
      setTooltipForceOpen(true);
      setCartTooltip('Added to Cart!');
      setTimeout(() => {
        setTooltipForceOpen(false);
        setCartTooltip('View Cart');
      }, 2000);
    }
    cartCount.current = content.length;
  }, [content.length, buyInProgress]);

  const [notificationsHovered, setNotificationsHovered] = React.useState(false);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.container}>
        {!leftActionsOverride && (
          <BackButton
            icon={'arrow-left-s-line'}
            className={clsx(
              PAGES ? styles.marginLeft : null,
              router.pathname === '/' ? styles.hidden : null
            )}
            onBack={preventBack ? () => {} : undefined}
          />
        )}
        {leftActions}
        <div
          className={styles.title}
          style={{
            paddingLeft: '100px',
          }}
        >
          {title ? title : <SearchBar />}
        </div>
        {rightActions}
        {!rightActionsOverride && (
          <div>
            {user.isLoggedIn && (
              <Tooltip text="Wallet" side="bottom">
                <>
                  {address && (
                    <Button
                      icon
                      onClick={() => {
                        TezBalanceActions.updateBalance(address);
                      }}
                      data-updating={loading}
                    >
                      <RemixIcon icon="refresh-line" size={16} />
                    </Button>
                  )}
                  <Link href="/wallet">
                    <span>{balance}</span> {balance && 'xtz'}
                  </Link>
                </>
              </Tooltip>
            )}
            {SHOPPING_CART && (
              <ShoppingCartDialog
                trigger={
                  <Button
                    tooltip={cartTooltip}
                    tooltipSide="bottom"
                    tooltipForceOpen={buyInProgress ? true : tooltipForceOpen}
                    tooltipClassName={clsx(
                      cartTooltip === 'View Cart' ? null : styles.cartTooltip
                    )}
                    icon
                    className={styles.cart}
                    disabled={buyInProgress}
                  >
                    <RemixIcon
                      icon={`shopping-cart-2-${
                        !content.length ? 'line' : 'fill'
                      }`}
                      size={24}
                    />
                    {content.length > 0 && (
                      <span>
                        {buyInProgress ? (
                          <RemixIcon
                            icon="loader-4-fill"
                            size={16}
                            className={styles.spin}
                          />
                        ) : (
                          <span>
                            {content.reduce(
                              (acc, item) => acc + item.quantity,
                              0
                            )}
                          </span>
                        )}
                      </span>
                    )}
                  </Button>
                }
              />
            )}
            {user.isLoggedIn && (
              <Button
                tooltip={
                  unreadCount > 0 && !notificationsHovered
                    ? `You have ${unreadCount} notification${
                        unreadCount > 1 ? 's' : ''
                      }`
                    : 'Notifications'
                }
                tooltipForceOpen={unreadCount > 0}
                tooltipClassName={clsx(
                  unreadCount > 0 && !notificationsHovered
                    ? styles.cartTooltip
                    : null
                )}
                tooltipSide="bottom"
                icon
                className={styles.cart}
                onMouseEnter={() => setNotificationsHovered(true)}
              >
                <Menu
                  element={close => {
                    return (
                      <Suspense fallback={<InfiniteLoader height={'70vh'} />}>
                        <NotificationsWindowBody
                          setPage={() => {}}
                          close={link => {
                            if (link && link.startsWith(`/item/`)) {
                              const tokenId = link.split(`/item/`)[1];
                              router
                                .push(
                                  {
                                    pathname: router.pathname,
                                    query: {...router.query},
                                  },
                                  getMediaLink(tokenId, undefined),
                                  {shallow: true}
                                )
                                .then(() => {
                                  ItemModalActions.setItem(true, tokenId);
                                });
                            } else if (link) {
                              router.push(link);
                            }
                            close?.();
                          }}
                        />
                      </Suspense>
                    );
                  }}
                  className={styles.notificationsMenu}
                  asModal
                >
                  <div className={styles.cart}>
                    <RemixIcon
                      icon={`notification-3-${!unreadCount ? 'line' : 'fill'}`}
                      size={24}
                    />
                    {unreadCount > 0 && (
                      <span>
                        <span>{unreadCount > 99 ? '99+' : unreadCount}</span>
                      </span>
                    )}
                  </div>
                </Menu>
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const SearchBar = () => {
  return (
    <SearchDialog
      trigger={
        <div>
          <Tooltip text="Search" side="bottom">
            <div className={styles.searchBar}>
              <RemixIcon icon="search-2-line" size={20} />
              <span>Search for people and groups</span>
            </div>
          </Tooltip>
        </div>
      }
    />
  );
};
