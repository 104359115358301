import {REPORTS_CHANNEL_ID} from '@/config';
import * as config from '../config.json';

const {reportUri} = config;

export async function SendReport(accountId: string, message: string) {
  await fetch(reportUri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account_id: parseInt(accountId),
      group_id: parseInt(REPORTS_CHANNEL_ID),
      content: message,
    }),
  });
}

type ReportType =
  | 'message'
  | 'account'
  | 'group'
  | 'comment'
  | 'post'
  | 'other';

type ReportReason =
  | 'spam'
  | 'harassment'
  | 'hate_speech'
  | 'violence'
  | 'other';

export function generateReport({
  type,
  reason,
  reportBy,
  reportFor,
  messageId,
  accountId,
  groupId,
  commentId,
  postId,
  details,
}: {
  type: ReportType;
  reason: ReportReason;
  reportBy: string;
  reportFor: string;
  messageId?: string;
  accountId?: string;
  groupId?: string;
  commentId?: string;
  postId?: string;
  details?: string;
}) {
  const header = `[${type.toUpperCase()}] [${reason.toUpperCase()}]\n${
    reportBy.startsWith('@') ? reportBy : `AccountId ${reportBy}`
  } reported ${
    reportFor.startsWith('@') ? reportFor : `AccountId ${reportFor}`
  }\n\n`;

  let body = '';

  if (accountId) {
    body += `AccountId: ${accountId}\n`;
  }
  if (postId) {
    body += `PostId: ${postId}\n`;
  }
  if (messageId) {
    body += `MessageId: ${messageId}\n`;
  }
  if (groupId) {
    body += `GroupId: ${groupId}\n`;
  }
  if (commentId) {
    body += `CommentId: ${commentId}\n`;
  }
  if (details) {
    body += `\nMore Details:\n${details}`;
  } else {
    body += '\nNo additional details provided.';
  }
  return header + body;
}
