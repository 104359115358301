import React from 'react';
import styles from '@/view/styles/components/Inbox/Notifications/NotificationItem.module.scss';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {trim} from '@/utils/trim';
import {getImageUrl} from '@/utils/conversions/conversions';
import {updateTimeSince} from '@/utils/timer';
import {format} from 'timeago.js';
import FollowButton from '../../FollowButton';
import Image from '../../Image';
import RemixIcon from '../../RemixIcon';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import UserProfileItem from '../../UserProfileItem';
import TimestampTooltip from '../../TimestampTooltip';
import {NotificationItemFragment$key} from './__generated__/NotificationItemFragment.graphql';

const NotificationItemFragment = graphql`
  fragment NotificationItemFragment on notifications {
    id
    type
    created_at
    sender {
      identities {
        id
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
      ...UserProfileItemFragment
    }
    comments {
      content
    }
    tezos_tokens {
      id
      title
      thumbnail_uri
    }
    playlists {
      title
    }
    reaction
    price
  }
`;

export default function NotificationItem({
  close,
  itemKey,
  view = 'default',
  isRead,
}: {
  close: (link?: string) => void;
  itemKey: NotificationItemFragment$key;
  view?: 'default' | 'simple';
  isRead?: boolean;
}) {
  const item = useFragment(NotificationItemFragment, itemKey);

  const {isMobile} = useBreakpoint();

  const getNotificationImage = () => {
    return (
      item.sender && (
        <UserProfileItem user={item.sender} hideText showFollowButton />
      )
    );
  };

  const getNotificationUser = () => {
    const username = trim(
      item.sender?.identities?.profilesCollection?.edges?.[0].node.username ||
        ''
    );
    return `${username}`;
  };

  const getNotificationDescription = () => {
    switch (item.type) {
      case 'follow':
        return `is now following you`;
      case 'comment':
        return `commented "${item.comments?.content}" on ${item.tezos_tokens?.title}`;
      case 'collect_token':
        return `collected ${item.tezos_tokens?.title}!`;
      case 'add_token_to_playlist':
        return `added ${item.tezos_tokens?.title} to ${item.playlists?.title}`;
      case 'add_reaction':
        return `reacted with ${item.reaction} to ${item.tezos_tokens?.title}`;
      case 'list_token_for_sale':
        return `listed ${item.tezos_tokens?.title} for ${item.price}`;
      case 'comment_reply':
        return `replied to your comment`;
      case 'add_comment_reaction':
        return `reacted to your comment`;
      case 'mention':
        return `mentioned you on a post`;
      case 'comment_mention':
        return `mentioned you in a comment`;
      default:
        return `unknown`;
    }
  };

  const getNotificationLink = () => {
    switch (item.type) {
      case 'follow':
        return `/${
          item.sender?.identities?.profilesCollection?.edges?.[0].node
            .username ||
          item.sender?.identities?.id ||
          '#'
        }`;
      case 'collect_token':
      case 'comment':
      case 'add_token_to_playlist':
      case 'add_reaction':
      case 'list_token_for_sale':
      case 'comment_reply':
      case 'add_comment_reaction':
      case 'mention':
      case 'comment_mention':
        return `/item/${item.tezos_tokens?.id}`;
      default:
        return `#`;
    }
  };

  const [age, setAge] = React.useState('');

  React.useEffect(() => {
    updateTimeSince(() => {
      setAge(
        format(
          (item?.created_at
            ? new Date(item.created_at).getTime()
            : undefined) || Date.now()
        )
      );
    });
  }, [item.created_at]);

  if (view === 'simple') {
    return (
      <li
        className={styles.root}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          close(getNotificationLink());
        }}
        data-view={view}
        data-is-read={isRead}
      >
        {getNotificationImage() && (
          <div
            onClick={() => {}}
            className={styles.imageContainer}
            data-view="simple"
          >
            {getNotificationImage()}
          </div>
        )}
        <div className={styles.text}>
          <span>
            {getNotificationUser()} {getNotificationDescription()}
          </span>
        </div>
        {!isRead && (
          <RemixIcon icon="circle-fill" size={6} color="var(--accentPrimary)" />
        )}
      </li>
    );
  }

  const getNotificationTitle = () => {
    const username = trim(
      item.sender?.identities?.profilesCollection?.edges?.[0].node.username ||
        ''
    );
    switch (item.type) {
      case 'follow':
        return `${username} is now following you`;
      case 'comment':
        return `${username} commented "${item.comments?.content}" on ${item.tezos_tokens?.title}`;
      case 'collect_token':
        return `${username} collected ${item.tezos_tokens?.title}!`;
      case 'add_token_to_playlist':
        return `${username} added ${item.tezos_tokens?.title} to ${item.playlists?.title}`;
      case 'add_reaction':
        return `${username} reacted to ${item.tezos_tokens?.title}`;
      case 'list_token_for_sale':
        return `${username} listed ${item.tezos_tokens?.title} for ${item.price}`;
      case 'comment_reply':
        return `${username} replied to your comment on ${item.tezos_tokens?.title}`;
      case 'add_comment_reaction':
        return `${username} reacted to your comment on ${item.tezos_tokens?.title}`;
      case 'mention':
        return `${username} mentioned you on a post`;
      case 'comment_mention':
        return `${username} mentioned you in a comment on ${item.tezos_tokens?.title}`;
      default:
        return `undefined`;
    }
  };

  const getNotificationAction = () => {
    switch (item.type) {
      case 'follow':
        return (
          <FollowButton
            hideWhenFollowing
            id={item.sender?.identities?.id || ''}
          />
        );
      case 'comment':
      case 'collect_token':
        return (
          <div className={styles.box}>
            <Image
              dynamic
              src={getImageUrl(item.tezos_tokens?.thumbnail_uri || '')}
              alt=""
            />
          </div>
        );
      case 'add_token_to_playlist':
        return (
          <div className={styles.box}>
            <Image
              dynamic
              src={getImageUrl(item.tezos_tokens?.thumbnail_uri || '')}
              alt=""
            />
          </div>
        );
      case 'add_reaction':
        return (
          <div className={styles.box}>
            <Image
              dynamic
              src={getImageUrl(item.tezos_tokens?.thumbnail_uri || '')}
              alt=""
            />
            <div className={styles.reactionLarge}>
              <span>{item.reaction}</span>
            </div>
          </div>
        );
      default:
        return undefined;
    }
  };

  return (
    <li
      className={styles.root}
      data-view={view}
      data-is-read={isRead}
      onClick={e => {
        close(getNotificationLink());
      }}
    >
      {getNotificationImage() && (
        <div onClick={() => {}} className={styles.imageContainer}>
          {getNotificationImage()}
        </div>
      )}
      <div className={styles.text} data-view={view}>
        <span>{getNotificationTitle()}</span>
        <TimestampTooltip date={item.created_at} />
      </div>
      <div className={styles.action}>{getNotificationAction()}</div>
    </li>
  );
}
