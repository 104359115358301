/**
 * @generated SignedSource<<9aa4c35b83213409c0813ab9ff2fa298>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type groupsFilter = {
  and?: ReadonlyArray<groupsFilter> | null;
  created_at?: DatetimeFilter | null;
  description?: StringFilter | null;
  id?: BigIntFilter | null;
  is_channel?: BooleanFilter | null;
  is_private?: BooleanFilter | null;
  nodeId?: IDFilter | null;
  not?: groupsFilter | null;
  or?: ReadonlyArray<groupsFilter> | null;
  title?: StringFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type BooleanFilter = {
  eq?: boolean | null;
  is?: FilterIs | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type removeGroupMutation$variables = {
  filter: groupsFilter;
};
export type removeGroupMutation$data = {
  readonly deleteFromgroupsCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
      readonly nodeId: string;
    }>;
  };
};
export type removeGroupMutation = {
  response: removeGroupMutation$data;
  variables: removeGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "groupsDeleteResponse",
    "kind": "LinkedField",
    "name": "deleteFromgroupsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "groups",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5409dce5e72849688e9943cbf3cd9e97",
    "id": null,
    "metadata": {},
    "name": "removeGroupMutation",
    "operationKind": "mutation",
    "text": "mutation removeGroupMutation(\n  $filter: groupsFilter!\n) {\n  deleteFromgroupsCollection(filter: $filter) {\n    records {\n      id\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "43be68f69bc97c5435cdaabb91c477fb";

export default node;
