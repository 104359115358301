/**
 * @generated SignedSource<<d5273d72599951c1b4bd3b0fd8586829>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type groupsInsertInput = {
  created_at?: string | null;
  description?: string | null;
  is_channel?: boolean | null;
  is_private?: boolean | null;
  title?: string | null;
  updated_at?: string | null;
};
export type insertGroupMutation$variables = {
  input: ReadonlyArray<groupsInsertInput>;
};
export type insertGroupMutation$data = {
  readonly insertIntogroupsCollection: {
    readonly records: ReadonlyArray<{
      readonly description: string | null;
      readonly id: string;
      readonly is_channel: boolean;
      readonly is_private: boolean;
      readonly nodeId: string;
      readonly title: string | null;
    }>;
  } | null;
};
export type insertGroupMutation = {
  response: insertGroupMutation$data;
  variables: insertGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "input"
      }
    ],
    "concreteType": "groupsInsertResponse",
    "kind": "LinkedField",
    "name": "insertIntogroupsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "groups",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_channel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_private",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "61cc32613e32bd5bd73c4a34c2a798ef",
    "id": null,
    "metadata": {},
    "name": "insertGroupMutation",
    "operationKind": "mutation",
    "text": "mutation insertGroupMutation(\n  $input: [groupsInsertInput!]!\n) {\n  insertIntogroupsCollection(objects: $input) {\n    records {\n      id\n      nodeId\n      title\n      description\n      is_channel\n      is_private\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "247f54f908343e28c75ef96c9249a9a2";

export default node;
