import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/CreateChannel/CreateChannelDialog.module.scss';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import CreateChannel from './CreateChannel';

export const CreateChannelDialog = ({
  trigger,
  retry,
}: {
  trigger: React.ReactNode;
  retry?: () => void;
}) => {
  return (
    <BottomSheet
      dialogOnDesktop
      trigger={trigger}
      className={styles.bottomSheet}
      handleOnClose={(isPopState?: boolean) => {}}
    >
      {useCallback(
        (closeDialog: () => void) => (
          <Suspense fallback={<></>}>
            <CreateChannel
              className={styles.modal}
              closeDialog={() => {
                closeDialog();
                retry?.();
              }}
            />
          </Suspense>
        ),
        [retry]
      )}
    </BottomSheet>
  );
};
