import React, {Suspense} from 'react';
import styles from '@/view/styles/components/GroupMembers/GroupMembers.module.scss';
import clsx from 'clsx';
import {useAccount} from '@/kits/account-kit/src';
import RemixIcon from '../RemixIcon';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
  usePagination,
} from '@/kits/relay-kit/src';
import updateAccountsGroupMutation from '@/graphql/update-accounts-groups';
import type {updateAccountsGroupsMutation as UpdateAccountsGroupMutationType} from '@/graphql/__generated__/updateAccountsGroupsMutation.graphql';
import {ToastActions} from '@/state/hooks/toasts';
import Button from '../Button';
import removeAccountFromGroupMutation from '@/graphql/remove-account-from-group';
import type {removeAccountFromGroupMutation as RemoveAccountFromGroupMutationType} from '@/graphql/__generated__/removeAccountFromGroupMutation.graphql';
import UserProfileItem from '../UserProfileItem';
import {MenuOptionType} from '@/view/types/types';
import Menu from '../Menu';
import {InviteToGroupDialog} from '../InviteToGroup/InviteToGroupDialog';
import Tooltip from '../Tooltip';
import InfiniteLoader from '../InfiniteLoader';
import type {GroupMembersMembersQuery as GroupMembersMembersQueryType} from './__generated__/GroupMembersMembersQuery.graphql';
import type {GroupMembersMyRoleQuery as GroupMembersMyRoleQueryType} from './__generated__/GroupMembersMyRoleQuery.graphql';
import {GroupMembersMembersPaginated$key} from './__generated__/GroupMembersMembersPaginated.graphql';
import {GroupMembersMembersPaginatedQuery} from './__generated__/GroupMembersMembersPaginatedQuery.graphql';
import type {GroupMembersGetGroupsQuery as GroupMembersGetGroupsQueryType} from './__generated__/GroupMembersGetGroupsQuery.graphql';
import {GroupMembersMemberItemFragment$key} from './__generated__/GroupMembersMemberItemFragment.graphql';

const GroupMembersMembersQuery = graphql`
  query GroupMembersMembersQuery($groupId: BigInt!) {
    ...GroupMembersMembersPaginated @arguments(groupId: $groupId)
  }
`;

const GroupMembersMyRoleQuery = graphql`
  query GroupMembersMyRoleQuery($groupId: BigInt!, $accountId: BigInt!) {
    accounts_groupsCollection(
      filter: {group_id: {eq: $groupId}, account_id: {eq: $accountId}}
    ) {
      edges {
        node {
          execute
        }
      }
    }
  }
`;

export default function GroupMembers({
  className,
  groupId,
  isGroup,
  isChannel,
  setOwnerUsername,
}: {
  className?: string;
  groupId: string;
  isGroup: boolean;
  isChannel: boolean;
  setOwnerUsername?: (username: string) => void;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {data: members, retry} = useLazyLoadQuery<GroupMembersMembersQueryType>(
    GroupMembersMembersQuery,
    {
      groupId: groupId,
    },
    {
      skip: !groupId || groupId === '',
    }
  );

  const {data: myRole} = useLazyLoadQuery<GroupMembersMyRoleQueryType>(
    GroupMembersMyRoleQuery,
    {
      groupId: groupId,
      accountId: accountId || '',
    },
    {
      skip: !groupId || groupId === '' || !accountId || accountId === '',
    }
  );

  const amIAdmin =
    myRole?.accounts_groupsCollection?.edges?.[0]?.node?.execute || false;

  return (
    <div className={clsx(styles.root, className)}>
      <div>
        <span>Members</span>
        {amIAdmin && (
          <InviteToGroupDialog
            trigger={
              <Button text>
                <span>Add people</span>
              </Button>
            }
            groupId={groupId || ''}
          />
        )}
      </div>
      <div className={styles.content}>
        <Suspense fallback={<InfiniteLoader />}>
          <GroupMembersPaginated
            members={members}
            groupId={groupId}
            retry={retry}
            amIAdmin={amIAdmin}
            isGroup={isGroup}
            isChannel={isChannel}
            setOwnerUsername={setOwnerUsername}
          />
        </Suspense>
      </div>
    </div>
  );
}

const GroupMembersMembersPaginated = graphql`
  fragment GroupMembersMembersPaginated on Query
  @argumentDefinitions(
    groupId: {type: "BigInt!"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "GroupMembersMembersPaginatedQuery") {
    accounts_groupsCollection(
      filter: {group_id: {eq: $groupId}}
      orderBy: {execute: DescNullsLast, created_at: AscNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "GroupMembersMembersPaginated_accounts_groupsCollection"
      ) {
      edges {
        node {
          nodeId
          id
          execute
          accounts {
            id
            nodeId
            identities {
              profilesCollection {
                edges {
                  node {
                    username
                  }
                }
              }
            }
          }
          ...GroupMembersMemberItemFragment
        }
      }
    }
  }
`;

const GroupMembersGetGroupsQuery = graphql`
  query GroupMembersGetGroupsQuery($accountId: BigInt!) {
    accounts_groupsCollection(
      filter: {account_id: {eq: $accountId}}
      first: 100
    ) {
      edges {
        node {
          id
          nodeId
          group_id
        }
      }
    }
  }
`;

const GroupMembersPaginated = ({
  members,
  groupId,
  retry,
  amIAdmin,
  isGroup,
  isChannel,
  setOwnerUsername,
}: {
  members: GroupMembersMembersPaginated$key | null | undefined;
  groupId: string;
  retry: () => void;
  amIAdmin: boolean;
  isGroup: boolean;
  isChannel: boolean;
  setOwnerUsername?: (username: string) => void;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {retry: refetchGroups} =
    useLazyLoadQuery<GroupMembersGetGroupsQueryType>(
      GroupMembersGetGroupsQuery,
      {
        accountId: accountId || '',
      },
      {
        skip: !accountId || accountId === '',
      }
    );

  const {
    data: paginatedMembers,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    GroupMembersMembersPaginatedQuery,
    GroupMembersMembersPaginated$key
  >(GroupMembersMembersPaginated, members);

  const groupCreator =
    paginatedMembers?.accounts_groupsCollection?.edges?.[0]?.node?.accounts.id;

  React.useEffect(() => {
    if (isGroup || isChannel) {
      setOwnerUsername?.(
        paginatedMembers?.accounts_groupsCollection?.edges?.[0]?.node?.accounts
          .identities.profilesCollection?.edges?.[0]?.node?.username || ''
      );
    }
  }, [isGroup, isChannel, paginatedMembers]);

  return (
    <>
      {paginatedMembers?.accounts_groupsCollection?.edges
        ?.map(edge => edge)
        ?.sort((a, b) => {
          if (a.node.accounts.id === accountId) return -1;
          if (b.node.accounts.id === accountId) return 1;
          if (a.node.execute) {
            if (b.node.accounts.id === groupCreator) {
              return 1;
            } else {
              return -1;
            }
          }
          if (a.node.execute) return 1;
          return 0;
        })
        ?.map((member, index) => {
          return (
            <MemberItem
              key={member.node.accounts.id + index}
              memberKey={member.node}
              retry={retry}
              refetchGroups={refetchGroups}
              groupId={groupId}
              amIAdmin={amIAdmin}
              isGroup={isGroup}
              isChannel={isChannel}
              groupCreator={groupCreator}
            />
          );
        })}
      {hasNext && (
        <Button
          text
          onClick={
            hasNext && !isLoadingNext && !isLoading
              ? () => {
                  loadNext(10);
                }
              : () => {}
          }
          className={styles.loadMore}
        >
          Load more
        </Button>
      )}
    </>
  );
};

const GroupMembersMemberItemFragment = graphql`
  fragment GroupMembersMemberItemFragment on accounts_groups {
    id
    nodeId
    execute
    write
    accounts {
      id
      nodeId
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
      ...UserProfileItemFragment
    }
  }
`;

const MemberItem = ({
  memberKey,
  retry,
  refetchGroups,
  groupId,
  amIAdmin,
  isGroup,
  isChannel,
  groupCreator,
}: {
  memberKey: GroupMembersMemberItemFragment$key;
  retry: () => void;
  refetchGroups: () => void;
  groupId: string;
  amIAdmin: boolean;
  isGroup: boolean;
  isChannel: boolean;
  groupCreator?: string;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const member = useFragment(GroupMembersMemberItemFragment, memberKey);

  const isAdmin = isGroup && !isChannel ? member?.execute : member?.write;
  const isOwner =
    isGroup && !isChannel
      ? member.accounts.id === groupCreator
      : isChannel
      ? member?.execute
      : false;
  const isYou = member.accounts.id === accountId;

  const [updateAccountsGroup] = useMutation<UpdateAccountsGroupMutationType>(
    updateAccountsGroupMutation
  );

  const [removeAccountsFromGroup] =
    useMutation<RemoveAccountFromGroupMutationType>(
      removeAccountFromGroupMutation
    );

  const menuOptions: MenuOptionType[] = [
    ...(member.accounts.id === accountId
      ? [
          {
            icon: <RemixIcon icon="logout-box-line" size={16} />,
            label: 'Leave',
            value: 'leave',
            onClick: () => {
              removeAccountsFromGroup({
                variables: {
                  filter: {
                    and: [
                      {
                        group_id: {eq: groupId},
                      },
                      {
                        account_id: {
                          eq: accountId,
                        },
                      },
                    ],
                  },
                },
                onCompleted(response) {
                  ToastActions.addToast(
                    response.deleteFromaccounts_groupsCollection?.records?.[0]
                      ?.nodeId + ' removed',
                    'Left conversation',
                    '',
                    'success'
                  );
                  retry();
                  refetchGroups?.();
                },
                onError(error) {
                  ToastActions.addToast(
                    error.message || 'Error leaving conversation',
                    'Error leaving conversation',
                    '',
                    'failure'
                  );
                  retry();
                },
              });
            },
          },
        ]
      : []),
    ...(amIAdmin
      ? [
          ...(!isYou && !isOwner
            ? [
                {
                  icon: <RemixIcon icon="user-forbid-line" size={16} />,
                  label: 'Remove',
                  value: 'remove',
                  onClick: () => {
                    removeAccountsFromGroup({
                      variables: {
                        filter: {
                          and: [
                            {
                              group_id: {eq: groupId},
                            },
                            {
                              account_id: {
                                eq: member.accounts.id,
                              },
                            },
                          ],
                        },
                      },
                      onCompleted(response) {
                        ToastActions.addToast(
                          response.deleteFromaccounts_groupsCollection
                            ?.records?.[0]?.nodeId + ' removed',
                          'Member removed',
                          '',
                          'success'
                        );
                        retry();
                      },
                      onError(error) {
                        ToastActions.addToast(
                          error.message || 'Error removing member',
                          'Error removing member',
                          '',
                          'failure'
                        );
                        retry();
                      },
                    });
                  },
                },
                ...(isAdmin
                  ? [
                      {
                        icon: <RemixIcon icon="close-line" size={16} />,
                        label: 'Demote',
                        value: 'demote',
                        onClick: () => {
                          updateAccountsGroup({
                            variables: {
                              filter: {
                                group_id: {eq: groupId},
                                account_id: {
                                  eq: member.accounts.id,
                                },
                              },
                              input: isChannel
                                ? {
                                    write: false,
                                  }
                                : {
                                    execute: false,
                                  },
                            },
                            onCompleted(response) {
                              ToastActions.addToast(
                                response.updateaccounts_groupsCollection
                                  ?.records?.[0]?.nodeId + ' demoted',
                                'Admin demoted',
                                '',
                                'success'
                              );
                              retry();
                            },
                            onError(error) {
                              ToastActions.addToast(
                                error.message || 'Error demoting admin',
                                'Error demoting admin',
                                '',
                                'failure'
                              );
                              retry();
                            },
                          });
                        },
                      },
                    ]
                  : [
                      {
                        icon: <RemixIcon icon="user-add-line" size={16} />,
                        label: 'Make admin',
                        value: 'promote',
                        onClick: () => {
                          updateAccountsGroup({
                            variables: {
                              filter: {
                                group_id: {eq: groupId},
                                account_id: {
                                  eq: member.accounts.id,
                                },
                              },
                              input: isChannel
                                ? {
                                    write: true,
                                  }
                                : {
                                    execute: true,
                                  },
                            },
                            onCompleted(response) {
                              ToastActions.addToast(
                                response.updateaccounts_groupsCollection
                                  ?.records?.[0]?.nodeId + ' promoted',
                                'Admin promoted',
                                '',
                                'success'
                              );
                              retry();
                            },
                            onError(error) {
                              ToastActions.addToast(
                                error.message || 'Error promoting admin',
                                'Error promoting admin',
                                '',
                                'failure'
                              );
                              retry();
                            },
                          });
                        },
                      },
                    ]),
              ]
            : []),
        ]
      : []),
  ];

  return (
    <div key={member.accounts.id} className={styles.member}>
      <div>
        <UserProfileItem user={member.accounts} hideText />
        <div>
          <span>
            {member.accounts.identities.profilesCollection?.edges?.[0]?.node
              ?.username || 'Unknown'}{' '}
            {(isOwner || isAdmin) && (
              <Tooltip
                text={isOwner ? 'Owner' : 'Admin'}
                className={styles.badge}
              >
                <RemixIcon
                  icon={`${isOwner ? 'vip-crown' : 'star'}-fill`}
                  size={10}
                  color={
                    isOwner
                      ? '#FFD700'
                      : isAdmin
                      ? 'var(--textSecondary)'
                      : '#fff'
                  }
                />
              </Tooltip>
            )}
          </span>
          <span data-admin={isAdmin}>
            {isOwner
              ? 'Owner'
              : isAdmin
              ? 'Admin'
              : isChannel && member?.write
              ? 'Admin'
              : 'Member'}{' '}
            {isYou ? '(You)' : ''}
          </span>
        </div>
      </div>
      {(isChannel || isGroup) && menuOptions.length > 0 && (
        <Menu options={menuOptions} asModal side="left">
          <span>
            <RemixIcon icon="more-2-fill" size={16} />
          </span>
        </Menu>
      )}
    </div>
  );
};
