import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeGroupMutation($filter: groupsFilter!) {
    deleteFromgroupsCollection(filter: $filter) {
      records {
        id
        nodeId
      }
    }
  }
`;
