import React, {Suspense} from 'react';
import styles from '@/view/styles/components/InviteToGroup/InviteToGroup.module.scss';
import clsx from 'clsx';
import Button from '../Button';
import Input from '../Input/Input';
import {trim} from '@/utils/trim';
import {getImageUrl} from '@/utils/conversions/conversions';
import {useAccount} from '@/kits/account-kit/src';
import {UsernameSuggestions} from '../NewPostCard/NewPostCardDetails';
import RemixIcon from '../RemixIcon';
import {Avatar} from '../Avatar';
import {graphql, useLazyLoadQuery, useMutation} from '@/kits/relay-kit/src';
import insertAccountsInGroupMutation from '@/graphql/insert-accounts-in-group';
import type {insertAccountsInGroupMutation as InsertAccountsInGroupMutationType} from '@/graphql/__generated__/insertAccountsInGroupMutation.graphql';
import removeAccountFromGroupMutation from '@/graphql/remove-account-from-group';
import type {removeAccountFromGroupMutation as RemoveAccountFromGroupMutationType} from '@/graphql/__generated__/removeAccountFromGroupMutation.graphql';
import insertGroupMutation from '@/graphql/insert-group';
import type {insertGroupMutation as InsertGroupMutationType} from '@/graphql/__generated__/insertGroupMutation.graphql';
import {ToastActions} from '@/state/hooks/toasts';
import type {InviteToGroupGetGroupsQuery as InviteToGroupGetGroupsQueryType} from './__generated__/InviteToGroupGetGroupsQuery.graphql';
import type {InviteToGroupGetGroupQuery as InviteToGroupGetGroupQueryType} from './__generated__/InviteToGroupGetGroupQuery.graphql';
import type {InviteToGroupGetGroupMembersQuery as InviteToGroupGetGroupMembersQueryType} from './__generated__/InviteToGroupGetGroupMembersQuery.graphql';

const InviteToGroupGetGroupsQuery = graphql`
  query InviteToGroupGetGroupsQuery($accountId: BigInt!) {
    accounts_groupsCollection(filter: {account_id: {eq: $accountId}}) {
      edges {
        node {
          groups {
            id
            nodeId
            is_private
          }
        }
      }
    }
  }
`;

const InviteToGroupGetGroupQuery = graphql`
  query InviteToGroupGetGroupQuery($groupId: BigInt!) {
    groupsCollection(filter: {id: {eq: $groupId}}) {
      edges {
        node {
          is_private
        }
      }
    }
  }
`;

const InviteToGroupGetGroupMembersQuery = graphql`
  query InviteToGroupGetGroupMembersQuery($groupId: BigInt!) {
    groupsCollection(filter: {id: {eq: $groupId}}) {
      edges {
        node {
          accounts_groupsCollection {
            edges {
              node {
                accounts {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function InviteToGroup({
  className,
  closeDialog,
  groupId,
}: {
  className?: string;
  closeDialog: () => void;
  groupId: string;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [searchUsername, setSearchUsername] = React.useState<string>('');

  const [triggerText, setTriggerText] = React.useState<string>('');

  React.useEffect(() => {
    const current = inputRef.current;
    if (!current) return;

    const handleInput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const value = target.value;
      const currentWord = value
        .slice(0, target.selectionStart || 0)
        .split(' ')
        .pop();
      const word = currentWord?.split('\n').pop();
      word && setTriggerText(word);
    };

    current.addEventListener('input', handleInput);

    return () => {
      current.removeEventListener('input', handleInput);
    };
  }, [searchUsername, setSearchUsername]);

  const [error, setError] = React.useState<string | null>(null);

  const [searchResults, setSearchResults] = React.useState<
    {
      username: string;
      accountId?: string;
      address?: string;
      identityId?: string;
      profilePicture?: string;
    }[]
  >([]);

  const {retry: refetchGroups} =
    useLazyLoadQuery<InviteToGroupGetGroupsQueryType>(
      InviteToGroupGetGroupsQuery,
      {
        accountId: accountId || '',
      },
      {
        skip: !accountId || accountId === '',
      }
    );

  const {data: groupData} = useLazyLoadQuery<InviteToGroupGetGroupQueryType>(
    InviteToGroupGetGroupQuery,
    {
      groupId: groupId,
    },
    {
      skip: !groupId || groupId === '',
    }
  );

  const {data: groupMembers, retry} =
    useLazyLoadQuery<InviteToGroupGetGroupMembersQueryType>(
      InviteToGroupGetGroupMembersQuery,
      {
        groupId,
      },
      {
        skip: !groupId || groupId === '',
      }
    );

  const isGroup =
    groupData?.groupsCollection?.edges?.[0]?.node?.is_private === false;

  return (
    <div className={clsx(styles.root, className)}>
      <div>
        <span>Add people to this conversation</span>
      </div>
      <div className={styles.content}>
        <Input
          ref={inputRef}
          type="text"
          simple
          fullWidth
          defaultValue={searchUsername}
          onChange={v => {
            setSearchUsername(v);
          }}
          placeholder="Search for a user to add"
        />
        <Suspense fallback={null}>
          <UsernameSuggestions
            triggerText={triggerText}
            addUsername={(username: string) => {
              if (inputRef.current) {
                const cursorPos = inputRef.current.selectionStart || 0;
                const textBeforeAll = searchUsername.slice(0, cursorPos);
                const textBefore = textBeforeAll.slice(
                  0,
                  textBeforeAll.length - triggerText.length
                );
                const textAfter = searchUsername.slice(cursorPos);
                const newText = `${textBefore}${username} ${textAfter}`;
                inputRef.current.value = newText.slice(1);
                setSearchUsername(newText.slice(1));
                inputRef.current.focus();
              }
              setTriggerText('');
            }}
            hideMenu
            setResults={res => {
              setSearchResults(res);
            }}
          />
        </Suspense>
        {searchResults.length !== 0 && (
          <div className={styles.results}>
            <div>Results</div>
            <div>
              {searchResults.map((res, i) => {
                const isMember =
                  groupMembers?.groupsCollection?.edges?.[0]?.node?.accounts_groupsCollection?.edges?.some(
                    edge => edge.node.accounts.id === res.accountId
                  );
                return (
                  <>
                    <SearchResult
                      key={res.accountId}
                      res={res}
                      isMember={!!isMember}
                      setError={setError}
                      retry={retry}
                      refetchGroups={refetchGroups}
                      groupId={groupId}
                      accountId={accountId || ''}
                      isGroup={isGroup}
                      groupMembers={
                        groupMembers?.groupsCollection?.edges?.[0]?.node?.accounts_groupsCollection?.edges?.map(
                          edge => edge.node.accounts.id
                        ) || []
                      }
                    />
                    {i < searchResults.length - 1 && (
                      <hr className={styles.divider} key={res.accountId} />
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {error && (
        <div className={styles.error}>
          <RemixIcon icon="error-warning-line" size={16} />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}

const SearchResult = ({
  res,
  isMember,
  setError,
  retry,
  refetchGroups,
  groupId,
  accountId,
  isGroup,
  groupMembers,
}: {
  res: {
    username: string;
    accountId?: string | undefined;
    address?: string | undefined;
    identityId?: string | undefined;
    profilePicture?: string | undefined;
  };
  isMember: boolean;
  setError: (error: string | null) => void;
  retry: () => void;
  refetchGroups: () => void;
  groupId: string;
  accountId: string;
  isGroup: boolean;
  groupMembers: string[];
}) => {
  const [insertGroup] =
    useMutation<InsertGroupMutationType>(insertGroupMutation);

  const [insertAccountsInGroup] =
    useMutation<InsertAccountsInGroupMutationType>(
      insertAccountsInGroupMutation
    );

  const [removeAccountsFromGroup] =
    useMutation<RemoveAccountFromGroupMutationType>(
      removeAccountFromGroupMutation
    );

  const [hovering, setHovering] = React.useState(false);
  return (
    <div key={res.accountId} className={styles.result}>
      <div>
        <Avatar src={getImageUrl(res.profilePicture || '')}>
          <RemixIcon icon="user-line" size={24} />
        </Avatar>
        <div>
          <span>{res.username}</span>
          <span>{trim(res.address || '')}</span>
        </div>
      </div>
      {res.accountId !== accountId && (
        <Button
          filled={!isMember}
          outlined={isMember}
          accent={isMember}
          onClick={() => {
            if (!isGroup && !isMember) {
              insertGroup({
                variables: {
                  input: [
                    {
                      title: 'New Group',
                      is_channel: false,
                      is_private: false,
                    },
                  ],
                },
                onCompleted(response) {
                  const newGroupId =
                    response.insertIntogroupsCollection?.records?.[0]?.id;
                  if (newGroupId) {
                    insertAccountsInGroup({
                      variables: {
                        input: [
                          ...(groupMembers?.map(memberId => ({
                            account_id: memberId,
                            group_id: newGroupId,
                            read: true,
                            write: true,
                            execute: memberId === accountId,
                          })) || []),
                          {
                            account_id: res.accountId,
                            group_id: newGroupId,
                            read: true,
                            write: true,
                            execute: false,
                          },
                        ],
                      },
                      onCompleted(response) {
                        ToastActions.addToast(
                          response.insertIntoaccounts_groupsCollection
                            ?.records?.[0]?.group_id || 'new-group',
                          'Group created and user added!',
                          '',
                          'success'
                        );
                        retry();
                        refetchGroups();
                      },
                      onError(error) {
                        setError(error.message);
                        setTimeout(() => {
                          setError(null);
                        }, 2000);
                        retry();
                      },
                    });
                  }
                },
              });
            } else {
              if (isMember) {
                removeAccountsFromGroup({
                  variables: {
                    filter: {
                      and: [
                        {
                          account_id: {
                            eq: res.accountId,
                          },
                        },
                        {
                          group_id: {
                            eq: groupId,
                          },
                        },
                      ],
                    },
                  },
                  onCompleted(response) {
                    retry();
                    refetchGroups();
                  },
                  onError(error) {
                    setError(error.message);
                    setTimeout(() => {
                      setError(null);
                    }, 2000);
                    retry();
                  },
                });
              } else {
                insertAccountsInGroup({
                  variables: {
                    input: [
                      {
                        account_id: res.accountId,
                        group_id: groupId,
                        read: true,
                        write: true,
                        execute: false,
                      },
                    ],
                  },
                  onCompleted(response) {
                    retry();
                    refetchGroups();
                  },
                  onError(error) {
                    setError(error.message);
                    setTimeout(() => {
                      setError(null);
                    }, 2000);
                    retry();
                  },
                });
              }
            }
          }}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          style={{
            ...(isMember && hovering
              ? {
                  color: 'var(--error)',
                  borderColor: 'var(--error)',
                }
              : {}),
          }}
        >
          {isMember && (
            <RemixIcon
              icon={`${hovering ? 'close' : 'check'}-line`}
              size={16}
            />
          )}
          {isMember ? (hovering ? 'Remove' : 'Added') : 'Add'}
        </Button>
      )}
    </div>
  );
};
