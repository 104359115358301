import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateAccountsGroupsMutation(
    $input: accounts_groupsUpdateInput!
    $filter: accounts_groupsFilter!
  ) {
    updateaccounts_groupsCollection(set: $input, filter: $filter) {
      records {
        nodeId
        id
        last_read_at
        read
        write
        execute
        muted
      }
    }
  }
`;
