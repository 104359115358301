/**
 * @generated SignedSource<<77119f4d95199954c835658e8802ade5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type groupsUpdateInput = {
  created_at?: string | null;
  description?: string | null;
  is_channel?: boolean | null;
  is_private?: boolean | null;
  title?: string | null;
  updated_at?: string | null;
};
export type groupsFilter = {
  and?: ReadonlyArray<groupsFilter> | null;
  created_at?: DatetimeFilter | null;
  description?: StringFilter | null;
  id?: BigIntFilter | null;
  is_channel?: BooleanFilter | null;
  is_private?: BooleanFilter | null;
  nodeId?: IDFilter | null;
  not?: groupsFilter | null;
  or?: ReadonlyArray<groupsFilter> | null;
  title?: StringFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type BooleanFilter = {
  eq?: boolean | null;
  is?: FilterIs | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateGroupMutation$variables = {
  filter: groupsFilter;
  input: groupsUpdateInput;
};
export type updateGroupMutation$data = {
  readonly updategroupsCollection: {
    readonly records: ReadonlyArray<{
      readonly description: string | null;
      readonly id: string;
      readonly is_channel: boolean;
      readonly is_private: boolean;
      readonly nodeId: string;
      readonly title: string | null;
    }>;
  };
};
export type updateGroupMutation = {
  response: updateGroupMutation$data;
  variables: updateGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "set",
        "variableName": "input"
      }
    ],
    "concreteType": "groupsUpdateResponse",
    "kind": "LinkedField",
    "name": "updategroupsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "groups",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_channel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_private",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateGroupMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateGroupMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0692c67e6f5b3b43dbeb8cbfbb4f337b",
    "id": null,
    "metadata": {},
    "name": "updateGroupMutation",
    "operationKind": "mutation",
    "text": "mutation updateGroupMutation(\n  $input: groupsUpdateInput!\n  $filter: groupsFilter!\n) {\n  updategroupsCollection(set: $input, filter: $filter) {\n    records {\n      nodeId\n      id\n      title\n      description\n      is_channel\n      is_private\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14c56b09b680ebb193cfcc5ddf03899f";

export default node;
