import React, {Suspense} from 'react';
import styles from '@/view/styles/components/Inbox/Messages/MessagesUserInfo.module.scss';
import {Avatar} from '../../Avatar';
import {getImageUrl} from '@/utils/conversions/conversions';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import FollowButton from '../../FollowButton';
import RemixIcon from '../../RemixIcon';
import Button from '../../Button';
import updateGroupMutation from '@/graphql/update-group';
import type {updateGroupMutation as UpdateGroupMutationType} from '@/graphql/__generated__/updateGroupMutation.graphql';
import {ToastActions} from '@/state/hooks/toasts';
import {ANNOUNCEMENTS_CHANNEL_ID} from '@/config';
import GroupMembers from '../../GroupMembers/GroupMembers';
import insertAccountsInGroupMutation from '@/graphql/insert-accounts-in-group';
import type {insertAccountsInGroupMutation as InsertAccountsInGroupMutationType} from '@/graphql/__generated__/insertAccountsInGroupMutation.graphql';
import InfiniteLoader from '../../InfiniteLoader';
import type {MessagesUserInfoFollowingQuery as MessagesUserInfoFollowingQueryType} from './__generated__/MessagesUserInfoFollowingQuery.graphql';
import {MessagesUserInfoUserProfileOnMessageWindowFragment$key} from './__generated__/MessagesUserInfoUserProfileOnMessageWindowFragment.graphql';
import {MessagesUserInfoUserProfileOnMessageWindowHeaderFragment$key} from './__generated__/MessagesUserInfoUserProfileOnMessageWindowHeaderFragment.graphql';
import {MessagesUserInfoUserProfileOnMessageWindowSidebarFragment$key} from './__generated__/MessagesUserInfoUserProfileOnMessageWindowSidebarFragment.graphql';
import type {MessagesUserInfoGetGroupMembersQuery as MessagesUserInfoGetGroupMembersQueryType} from './__generated__/MessagesUserInfoGetGroupMembersQuery.graphql';
import type {MessagesUserInfoGetGroupMemberQuery as MessagesUserInfoGetGroupMemberQueryType} from './__generated__/MessagesUserInfoGetGroupMemberQuery.graphql';

const MessagesUserInfoGetGroupMembersQuery = graphql`
  query MessagesUserInfoGetGroupMembersQuery($groupId: BigInt!) {
    groupsCollection(filter: {id: {eq: $groupId}}) {
      edges {
        node {
          id
          nodeId
          title
          description
          is_channel
          is_private
          totalCount: accounts_groupsCollection {
            totalCount
          }
          accounts_groupsCollection {
            edges {
              node {
                read
                write
                execute
                muted
                accounts {
                  id
                  nodeId
                  identities {
                    profilesCollection {
                      edges {
                        node {
                          username
                          avatar_uri
                        }
                      }
                    }
                  }
                  ...MessagesUserInfoUserProfileOnMessageWindowFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MessagesUserInfoGetGroupMemberQuery = graphql`
  query MessagesUserInfoGetGroupMemberQuery(
    $accountId: BigInt!
    $groupId: BigInt!
  ) {
    accounts_groupsCollection(
      filter: {group_id: {eq: $groupId}, account_id: {eq: $accountId}}
    ) {
      edges {
        node {
          execute
        }
      }
    }
  }
`;

export default function MessagesUserInfo({
  groupId,
  title,
  description,
  view,
  refetchGroups,
  setOwnerUsername,
}: {
  groupId: string;
  title?: string;
  description?: string;
  view?: 'default' | 'sidebar' | 'header';
  refetchGroups?: () => void;
  setOwnerUsername?: (username: string) => void;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const {data, retry} =
    useLazyLoadQuery<MessagesUserInfoGetGroupMembersQueryType>(
      MessagesUserInfoGetGroupMembersQuery,
      {groupId},
      {skip: !groupId || groupId === ''}
    );

  const {data: myRole, retry: refetchMyRole} =
    useLazyLoadQuery<MessagesUserInfoGetGroupMemberQueryType>(
      MessagesUserInfoGetGroupMemberQuery,
      {
        accountId: accountId || '',
        groupId: groupId || '',
      },
      {skip: !groupId || groupId === '' || !accountId || accountId === ''}
    );

  const isGroup =
    data?.groupsCollection?.edges?.[0]?.node?.is_private === false;

  const isChannel =
    data?.groupsCollection?.edges?.[0]?.node?.is_channel === true;

  const groupMembers = isGroup
    ? data?.groupsCollection?.edges?.[0]?.node?.accounts_groupsCollection?.edges?.map(
        edge =>
          edge.node.accounts.identities.profilesCollection?.edges?.[0]?.node
            ?.username || ''
      )
    : [];

  const totalMembersCount =
    data?.groupsCollection?.edges?.[0]?.node?.totalCount?.totalCount || 0;

  const groupTitle =
    title ||
    `${
      groupMembers
        ?.map(x => x)
        ?.slice(0, 3)
        ?.join(', ') || ''
    } ${
      totalMembersCount > 3
        ? `& ${totalMembersCount - 3} other${
            totalMembersCount - 3 > 1 ? 's' : ''
          }`
        : ''
    }`;

  const admin =
    myRole?.accounts_groupsCollection?.edges?.[0]?.node?.execute === true;

  const isMember = myRole?.accounts_groupsCollection?.edges?.length !== 0;

  const [updateGroup] =
    useMutation<UpdateGroupMutationType>(updateGroupMutation);
  const [insertAccountsInGroup] =
    useMutation<InsertAccountsInGroupMutationType>(
      insertAccountsInGroupMutation
    );

  if (isGroup || isChannel) {
    switch (view) {
      case 'header':
        return (
          <div className={styles.header}>
            <span>{groupTitle}</span>
          </div>
        );
      case 'sidebar':
        return (
          <div>
            {isChannel && (
              <div>
                <span>
                  <RemixIcon
                    icon={`${isChannel ? 'megaphone' : 'group'}-line`}
                    size={54}
                  />
                </span>
                <div>
                  <span>{groupTitle}</span>
                </div>
              </div>
            )}
            {description && (
              <div className={styles.description}>{description}</div>
            )}
            {groupId !== ANNOUNCEMENTS_CHANNEL_ID && isChannel && (
              <>
                <Button
                  gradient
                  onClick={() => {
                    if (!navigator.clipboard) {
                      return;
                    }
                    navigator.clipboard.writeText(
                      `${window.location.origin}/inbox/${groupId}`
                    );
                    ToastActions.addToast(
                      'Link copied to clipboard',
                      'Invite link copied',
                      '',
                      'success'
                    );
                  }}
                >
                  <RemixIcon icon="link" size={16} />
                  <span>Invite link</span>
                </Button>
                {!isMember && (
                  <Button
                    gradient
                    onClick={() => {
                      insertAccountsInGroup({
                        variables: {
                          input: [
                            {
                              account_id: accountId || '',
                              group_id: groupId,
                              read: true,
                              write: false,
                              execute: false,
                              muted: false,
                              last_read_at: 'now',
                            },
                          ],
                        },
                        onCompleted() {
                          refetchGroups?.();
                          retry();
                          refetchMyRole();
                        },
                        onError(error) {
                          ToastActions.addToast(
                            error.message || 'Error joining channel',
                            'Error joining channel',
                            '',
                            'failure'
                          );
                        },
                      });
                    }}
                  >
                    <span>Join channel</span>
                  </Button>
                )}
              </>
            )}
            <Suspense fallback={<InfiniteLoader />}>
              <GroupMembers
                groupId={groupId}
                isGroup={isGroup}
                isChannel={isChannel}
                setOwnerUsername={setOwnerUsername}
              />
            </Suspense>
          </div>
        );
      default:
        return (
          <div className={styles.root}>
            <span>
              <RemixIcon
                icon={`${isChannel ? 'megaphone' : 'group'}-line`}
                size={54}
              />
            </span>
            <div>{groupTitle}</div>
            <div>
              <span>This is the very beginning of the group</span>
            </div>
          </div>
        );
    }
  }

  const otherUser =
    data?.groupsCollection?.edges?.[0]?.node?.accounts_groupsCollection?.edges?.find(
      edge => edge?.node?.accounts.id !== accountId
    )?.node;
  return (
    otherUser?.accounts && (
      <Suspense fallback={<></>}>
        <UserProfileOnMessageWindow
          accountKey={otherUser?.accounts}
          view={view}
        />
      </Suspense>
    )
  );
}

const MessagesUserInfoFollowingQuery = graphql`
  query MessagesUserInfoFollowingQuery(
    $myIdentityId: BigInt!
    $theirIdentityId: BigInt!
  ) {
    followsCollection(
      filter: {
        follower_id: {eq: $myIdentityId}
        followee_id: {eq: $theirIdentityId}
      }
    ) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
  }
`;

const MessagesUserInfoUserProfileOnMessageWindowFragment = graphql`
  fragment MessagesUserInfoUserProfileOnMessageWindowFragment on accounts {
    id
    nodeId
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            avatar_uri
            bio
            username
            website
          }
        }
      }
    }
    ...MessagesUserInfoUserProfileOnMessageWindowHeaderFragment
    ...MessagesUserInfoUserProfileOnMessageWindowSidebarFragment
  }
`;

const UserProfileOnMessageWindow = ({
  accountKey,
  view,
}: {
  accountKey: MessagesUserInfoUserProfileOnMessageWindowFragment$key;
  view?: 'default' | 'sidebar' | 'header';
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;

  const account = useFragment(
    MessagesUserInfoUserProfileOnMessageWindowFragment,
    accountKey
  );

  const {data: amIFollowing} =
    useLazyLoadQuery<MessagesUserInfoFollowingQueryType>(
      MessagesUserInfoFollowingQuery,
      {
        myIdentityId: identityId || '',
        theirIdentityId: account.identities.id,
      },
      {
        skip:
          !identityId ||
          identityId === '' ||
          !account.identities.id ||
          account.identities.id === '',
      }
    );

  const {data: amIFollowed} =
    useLazyLoadQuery<MessagesUserInfoFollowingQueryType>(
      MessagesUserInfoFollowingQuery,
      {
        myIdentityId: account.identities.id,
        theirIdentityId: identityId || '',
      },
      {
        skip:
          !identityId ||
          identityId === '' ||
          !account.identities.id ||
          account.identities.id === '',
      }
    );

  if (view === 'sidebar') {
    return <UserProfileOnMessageWindowSidebar accountKey={account} />;
  }
  if (view === 'header') {
    return <UserProfileOnMessageWindowHeader accountKey={account} />;
  }

  const friends =
    amIFollowing?.followsCollection?.edges?.length !== 0 &&
    amIFollowed?.followsCollection?.edges?.length !== 0;

  const amIFollowingThem = amIFollowing?.followsCollection?.edges?.length !== 0;
  const amIFollowedByThem = amIFollowed?.followsCollection?.edges?.length !== 0;

  return (
    <div className={styles.root}>
      {!!account.identities.profilesCollection?.edges?.[0].node.avatar_uri ? (
        <Avatar
          src={getImageUrl(
            account.identities.profilesCollection?.edges?.[0].node.avatar_uri ||
              ''
          )}
        >
          <RemixIcon icon="user-line" size={42} />
        </Avatar>
      ) : (
        <RemixIcon icon="user-line" size={90} />
      )}
      <div>
        {account.identities.profilesCollection?.edges?.[0].node.username}
      </div>
      <div>
        <span>{`You're ${
          friends
            ? 'friends'
            : amIFollowingThem
            ? `following ${account.identities.profilesCollection?.edges?.[0].node.username}`
            : amIFollowedByThem
            ? `followed by ${account.identities.profilesCollection?.edges?.[0].node.username}`
            : 'not connected'
        } on DNS`}</span>
        <span>
          {account.identities.profilesCollection?.edges?.[0].node.bio}
        </span>
      </div>
    </div>
  );
};

const MessagesUserInfoUserProfileOnMessageWindowSidebarFragment = graphql`
  fragment MessagesUserInfoUserProfileOnMessageWindowSidebarFragment on accounts {
    id
    nodeId
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            avatar_uri
            bio
            username
            website
          }
        }
      }
    }
  }
`;

const UserProfileOnMessageWindowSidebar = ({
  accountKey,
}: {
  accountKey: MessagesUserInfoUserProfileOnMessageWindowSidebarFragment$key;
}) => {
  const account = useFragment(
    MessagesUserInfoUserProfileOnMessageWindowSidebarFragment,
    accountKey
  );
  return (
    <div>
      <div>
        {!!account.identities.profilesCollection?.edges?.[0].node.avatar_uri ? (
          <Avatar
            src={getImageUrl(
              account.identities.profilesCollection?.edges?.[0].node
                .avatar_uri || ''
            )}
          >
            <RemixIcon icon="user-line" size={42} />
          </Avatar>
        ) : (
          <span>
            <RemixIcon icon="user-line" size={42} />
          </span>
        )}
        <div>
          <span>
            {account.identities?.profilesCollection?.edges?.[0].node.username}
          </span>
          {/* <span>Active 4 hours ago</span> */}
        </div>
      </div>
      <FollowButton id={account.identities.id} />
      <div>{account.identities?.profilesCollection?.edges?.[0].node.bio}</div>
      <div>
        {[
          ...(account.identities?.profilesCollection?.edges?.[0].node.website
            ? [
                {
                  name: 'Website',
                  icon: <RemixIcon icon="earth-fill" size={16} />,
                  url: account.identities?.profilesCollection?.edges?.[0].node
                    .website,
                },
              ]
            : []),
        ]
          .slice(0, 3)
          .map((link, index) => (
            <a
              href={
                link.url?.startsWith('http') ? link.url : `https://${link.url}`
              }
              key={link.name + index}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div>{link.icon}</div>
              <span>{link.name}</span>
            </a>
          ))}
      </div>
    </div>
  );
};

const MessagesUserInfoUserProfileOnMessageWindowHeaderFragment = graphql`
  fragment MessagesUserInfoUserProfileOnMessageWindowHeaderFragment on accounts {
    id
    nodeId
    identities {
      profilesCollection {
        edges {
          node {
            avatar_uri
            username
          }
        }
      }
    }
  }
`;

const UserProfileOnMessageWindowHeader = ({
  accountKey,
}: {
  accountKey: MessagesUserInfoUserProfileOnMessageWindowHeaderFragment$key;
}) => {
  const account = useFragment(
    MessagesUserInfoUserProfileOnMessageWindowHeaderFragment,
    accountKey
  );
  return (
    <div className={styles.headerItem}>
      {!!account.identities.profilesCollection?.edges?.[0].node.avatar_uri ? (
        <Avatar
          src={getImageUrl(
            account.identities.profilesCollection?.edges?.[0].node.avatar_uri ||
              ''
          )}
        >
          <RemixIcon icon="user-line" size={24} />
        </Avatar>
      ) : (
        <RemixIcon icon="user-line" size={28} />
      )}
      <div>
        <span>
          {account.identities?.profilesCollection?.edges?.[0].node.username}
        </span>
      </div>
    </div>
  );
};
