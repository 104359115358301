import React, {Suspense} from 'react';
import {graphql, useLazyLoadQuery, useMutation} from '@/kits/relay-kit/src';
import styles from '@/view/styles/components/Inbox/Messages/MessageListItem.module.scss';
import UserProfileItem from '../../UserProfileItem';
import Skeleton from '../../Skeleton';
import {useAccount} from '@/kits/account-kit/src';
import {updateTimeSince} from '@/utils/timer';
import {format} from 'timeago.js';
import Button from '../../Button';
import RemixIcon from '../../RemixIcon';
import Menu from '../../Menu';
import removeMessageMutation from '@/graphql/remove-message';
import type {removeMessageMutation as MessageRemoveMutationType} from '@/graphql/__generated__/removeMessageMutation.graphql';
import TextClamp from '../../TextClamp';
import {ANNOUNCEMENTS_CHANNEL_ID, MESSAGE_REPLIES} from '@/config';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {CreateChannelDialog} from '../../CreateChannel/CreateChannelDialog';
import {
  NotificationsActions,
  useNotifications,
} from '@/state/hooks/notifications';
import {Avatar} from '../../Avatar';
import {getImageUrl} from '@/utils/conversions/conversions';
import {SendReport, generateReport} from '@/kits/report-kit/src';
import {ToastActions} from '@/state/hooks/toasts';
import ReactionChips from '../../ReactionChips';
import {ReactionTo} from '@/controller/hooks/eventRollup';
import clsx from 'clsx';
import QuoteCard from '../../FeedCard/QuoteCard';
import TimestampTooltip from '../../TimestampTooltip';
import type {MessageListItemMessageUserLoaderAccountQuery as MessageListItemMessageUserLoaderAccountQueryType} from './__generated__/MessageListItemMessageUserLoaderAccountQuery.graphql';
import type {MessageListItemGetMessageQuery as MessageListItemGetMessageQueryType} from './__generated__/MessageListItemGetMessageQuery.graphql';

export default function MessageListItem({
  onClick,
  view = 'default',
  user: messageOwnerAccountId,
  iconOverride,
  messageNodeId,
  messageId,
  message,
  isContinued,
  isFirst,
  isLast,
  title,
  createdAt,
  myUsername,
  active,
  isLoading,
  groupId,
  onDelete,
  isAReplyTo,
  replyingTo,
  setReplyToMessage,
  reactions: initReactions,
  addReaction,
  removeReaction,
  isUnread,
  isGroup,
  isChannel,
  channelMembers,
  totalMembersCount = 0,
  channelAdmin = false,
}: {
  onClick: () => void;
  view?: 'default' | 'simple';
  user: string;
  iconOverride?: string[];
  messageNodeId?: string;
  messageId?: string;
  message?: string;
  isContinued?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  title?: string;
  createdAt?: string;
  myUsername?: string;
  active?: boolean;
  isLoading?: boolean;
  groupId?: string;
  isAReplyTo?:
    | {
        parent_message_id: string;
        child_message_id: string;
        parentContent: string | undefined;
      }
    | undefined;
  onDelete?: () => void;
  replyingTo?: boolean;
  setReplyToMessage?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          nodeId: string;
          content: string;
          by: string;
        }
      | undefined
    >
  >;
  reactions?: {
    reaction: string;
    messageId: string;
    accountId: string;
    username: string;
  }[];
  addReaction?: (reaction: string, messageId: string) => void;
  removeReaction?: (reaction: string, messageId: string) => void;
  isUnread?: boolean;
  isGroup?: boolean;
  isChannel?: boolean;
  channelMembers?: (string | null | undefined)[];
  totalMembersCount?: number;
  channelAdmin?: boolean;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const accountId = user.isLoggedIn ? user.accountId : null;

  const isMyMessage =
    parseInt(accountId || '0') === parseInt(messageOwnerAccountId)
      ? true
      : false;

  const [age, setAge] = React.useState('');

  React.useEffect(() => {
    return updateTimeSince(() => {
      const age = format(
        (createdAt ? new Date(createdAt).getTime() : undefined) || Date.now()
      );
      setAge(age);
    });
  }, [createdAt]);

  const [messageSenderUsername, setMessageSenderUsername] = React.useState('');

  const [showReactions, setShowReactions] = React.useState(false);

  const [reactions, setReactions] = React.useState(() => initReactions);

  React.useEffect(() => {
    setReactions(initReactions);
  }, [initReactions]);

  const [quoteItem, setQuoteItem] = React.useState<string | undefined>(
    undefined
  );

  if (view === 'simple' && messageOwnerAccountId) {
    return (
      <RecentChatItem
        title={title}
        lastMessage={message}
        user={messageOwnerAccountId}
        iconOverride={iconOverride}
        onClick={onClick}
        active={active}
        isLoading={isLoading}
        groupId={groupId || ''}
        isUnread={isUnread}
        isGroup={isGroup}
        isChannel={isChannel}
        channelMembers={channelMembers}
        totalMembersCount={totalMembersCount}
      />
    );
  }
  // const hasImage = !!message?.content.includes('image');

  function highlightAndScrollToMessage(id: string) {
    const message = document.getElementById(`message-${id}`);
    if (message) {
      message.scrollIntoView({behavior: 'smooth', block: 'center'});
      message.style.backgroundColor = 'var(--chip)';
      setTimeout(() => {
        message.style.removeProperty('background-color');
      }, 1000);
    }
  }
  return (
    <div
      className={styles.root}
      data-is-continued={isContinued}
      data-is-first={isFirst}
      data-is-last={isLast}
      data-view={view}
      // data-has-image={hasImage}
      data-is-my-message={isMyMessage}
      data-active={active}
      data-is-replied-to={replyingTo}
      id={`message-${messageId}`}
    >
      {isFirst && !isMyMessage && (isGroup || isChannel) && (
        <span className={styles.username} data-is-continued={isContinued}>
          {messageSenderUsername}
        </span>
      )}
      <div>
        {!isMyMessage && messageOwnerAccountId && (
          <Suspense
            fallback={
              <Skeleton
                variant="circle"
                animation="wave"
                width={34}
                height={34}
              />
            }
          >
            <MessageUserLoader
              messageOwnerAccountId={messageOwnerAccountId}
              setUsername={setMessageSenderUsername}
            />
          </Suspense>
        )}
        <div
          className={styles.messageWrapper}
          data-is-my-message={isMyMessage}
          data-has-timestamp={createdAt && isLast}
          data-has-reactions={true}
        >
          <div data-is-my-message={isMyMessage}>
            <div
              className={styles.messageContainer}
              data-is-continued={isContinued}
              data-is-first={isFirst}
              data-is-last={isLast}
              // data-has-image={hasImage}
              data-is-my-message={isMyMessage}
            >
              <div>
                {isAReplyTo && (
                  <span
                    data-is-my-message={isMyMessage}
                    onClick={() => {
                      highlightAndScrollToMessage(isAReplyTo.parent_message_id);
                    }}
                  >
                    <Suspense
                      fallback={
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          height={27}
                          width={50}
                          borderRadius={4}
                        />
                      }
                    >
                      <ReplyToMessageBox isAReplyTo={isAReplyTo} />
                    </Suspense>
                  </span>
                )}
                <TextClamp
                  text={message || ''}
                  prefix={null}
                  maxChars={200}
                  enableMentions
                  enableHashtags
                  enableUrls
                  setQuoteItem={setQuoteItem}
                />
                {quoteItem && (
                  <QuoteCard
                    tokenId={quoteItem}
                    parentHasAsset={false}
                    className={styles.quoteCard}
                  />
                )}
              </div>
              <ReactionChips
                showPicker
                id={messageId || ''}
                nodeId={messageNodeId || ''}
                reactions={
                  reactions?.map(r => ({
                    by: r.accountId,
                    byUsername: r.username || 'Unknown',
                    id: r.messageId + r.reaction + r.accountId,
                    reaction: r.reaction,
                  })) || []
                }
                type={ReactionTo.Message}
                addToReactionsState={addReaction}
                removeFromReactionsState={removeReaction}
                max={5}
                className={clsx(
                  styles.reactionsContainer,
                  isMyMessage && styles.myReactionsContainer
                )}
              />
            </div>
            <MessageOptions
              message={message}
              messageId={messageId}
              myUsername={myUsername}
              messageBy={
                messageSenderUsername
                  ? `@${messageSenderUsername}`
                  : messageOwnerAccountId
              }
              isMyMessage={isMyMessage}
              groupId={groupId}
              onDelete={onDelete}
              setReplyToMessage={() => {
                setReplyToMessage?.({
                  id: messageId || '',
                  nodeId: '',
                  content: message || '',
                  by: isMyMessage ? 'You' : messageSenderUsername,
                });
              }}
              isChannel={isChannel}
              channelAdmin={channelAdmin}
            />
          </div>
          {createdAt && isLast && (
            <span
              className={styles.timestamp}
              data-is-my-message={isMyMessage}
              data-has-reactions={true}
            >
              <TimestampTooltip date={createdAt} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

const MessageListItemGetMessageQuery = graphql`
  query MessageListItemGetMessageQuery($id: BigInt!) {
    messagesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          content
        }
      }
    }
  }
`;

const ReplyToMessageBox = ({
  isAReplyTo,
}: {
  isAReplyTo: {
    parent_message_id: string;
    child_message_id: string;
    parentContent: string | undefined;
  };
}) => {
  const {data} = useLazyLoadQuery<MessageListItemGetMessageQueryType>(
    MessageListItemGetMessageQuery,
    {
      id: isAReplyTo.parent_message_id,
    },
    {
      skip:
        !isAReplyTo.parent_message_id ||
        isAReplyTo.parent_message_id === '' ||
        !!isAReplyTo.parentContent,
    }
  );
  const parentMessage =
    data?.messagesCollection?.edges?.[0]?.node.content ||
    isAReplyTo.parentContent ||
    '';
  return (
    <TextClamp text={parentMessage} prefix={null} maxChars={50} hideMore />
  );
};

const MessageOptions = ({
  message,
  messageId,
  myUsername,
  messageBy,
  isMyMessage,
  groupId,
  onDelete,
  setReplyToMessage,
  isChannel = false,
  channelAdmin = false,
}: {
  message?: string | undefined;
  messageId?: string;
  myUsername?: string;
  messageBy?: string;
  isMyMessage: boolean;
  groupId?: string;
  onDelete?: () => void;
  setReplyToMessage?: () => void;
  isChannel?: boolean;
  channelAdmin?: boolean;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : null;

  const [menuOpen, setMenuOpen] = React.useState(false);

  const [deleteMessage] = useMutation<MessageRemoveMutationType>(
    removeMessageMutation
  );

  return (
    <>
      {isMyMessage ? (
        <Menu
          side="left"
          options={[
            {
              icon: <RemixIcon icon="delete-bin-7-line" size={16} />,
              label: 'Delete',
              value: 'delete',
              onClick(e) {
                e.stopPropagation();
                deleteMessage({
                  variables: {
                    filter: {
                      and: [
                        {
                          account_id: {
                            eq: accountId,
                          },
                          group_id: {
                            eq: groupId,
                          },
                          id: {
                            eq: messageId,
                          },
                        },
                      ],
                    },
                  },
                  onCompleted() {
                    onDelete?.();
                  },
                });
              },
            },
          ]}
          onOpen={() => {
            setMenuOpen(true);
          }}
          onClose={() => {
            setMenuOpen(false);
          }}
        >
          <span data-show={menuOpen}>
            <Button icon>
              <RemixIcon icon="more-fill" size={16} />
            </Button>
          </span>
        </Menu>
      ) : (
        <Menu
          side="left"
          options={[
            ...(accountId
              ? [
                  {
                    icon: <RemixIcon icon="spam-2-line" size={16} />,
                    label: 'Report',
                    value: 'report',
                    onClick(e: any) {
                      e.stopPropagation();
                      const report = generateReport({
                        type: 'message',
                        reason: 'spam',
                        reportBy: myUsername ? `@${myUsername}` : accountId,
                        reportFor: messageBy || '',
                        messageId,
                        groupId,
                        details: `Message: ${message}`,
                      });
                      SendReport(accountId, report)
                        .then(res => {
                          ToastActions.addToast(
                            `report-${new Date().getTime()}`,
                            'Report sent!',
                            'Your report was sent successfully. Thank you!',
                            'success'
                          );
                        })
                        .catch(e => {
                          ToastActions.addToast(
                            `failed-report-${new Date().getTime()}`,
                            'Failed to send report',
                            'Try again or reach out to us via Discord',
                            'success',
                            () => {
                              window.open(
                                'https://discord.gg/earlyaccess',
                                '_blank',
                                'noopener noreferrer'
                              );
                            },
                            'Join'
                          );
                        });
                    },
                  },
                ]
              : []),
          ]}
          onOpen={() => {
            setMenuOpen(true);
          }}
          onClose={() => {
            setMenuOpen(false);
          }}
        >
          <span data-show={menuOpen}>
            <Button icon>
              <RemixIcon icon="more-fill" size={16} />
            </Button>
          </span>
        </Menu>
      )}
      {MESSAGE_REPLIES && (!isChannel || (isChannel && channelAdmin)) ? (
        <Button
          icon
          onClick={() => {
            setReplyToMessage?.();
          }}
        >
          <RemixIcon icon="reply-fill" size={16} />
        </Button>
      ) : null}
    </>
  );
};

export const RecentChatItemLoader = () => {
  return (
    <li className={styles.root}>
      <Skeleton variant="circle" animation="wave" width={34} height={34} />
      <div className={styles.text}>
        <Skeleton variant="text" animation="wave" width="60%" height={15} />
        <Skeleton
          variant="text"
          animation="wave"
          width="80%"
          height={14}
          styles={{
            marginTop: '2px',
          }}
        />
      </div>
    </li>
  );
};

const RecentChatItem = ({
  lastMessage,
  user: messageOwnerAccountId,
  iconOverride,
  onClick,
  title,
  active,
  isLoading,
  groupId,
  isUnread,
  isGroup,
  isChannel,
  channelMembers,
  totalMembersCount = 0,
}: {
  lastMessage: string | null | undefined;
  user: string;
  iconOverride?: string[];
  onClick: () => void;
  title?: string;
  active?: boolean;
  isLoading?: boolean;
  groupId: string;
  isUnread?: boolean;
  isGroup?: boolean;
  isChannel?: boolean;
  channelMembers?: (string | null | undefined)[];
  totalMembersCount?: number;
}) => {
  const router = useRouter();
  const {groupId: groupIdFromRoute} = router.query;

  const [username, setUsername] = React.useState<string>(() => {
    if (isGroup) {
      return (
        title ||
        `${channelMembers
          ?.map(x => x)
          ?.slice(0, 3)
          ?.join(', ')} ${
          totalMembersCount > 3
            ? `& ${totalMembersCount - 3} other${
                totalMembersCount - 3 > 1 ? 's' : ''
              }`
            : ''
        }` ||
        ''
      );
    }
    return '';
  });

  return (
    <li
      className={styles.root}
      onClick={e => {
        onClick();
        if (isUnread) {
          const unreadCount = useNotifications.getState().unreadMessageCount;
          if (unreadCount === 0) return;
          const newUnreadCount = unreadCount - 1;
          NotificationsActions.updateUnreadMessageCount(newUnreadCount);
        }
      }}
      data-active={active}
      data-loading={isLoading}
      data-view="simple"
    >
      <Link
        href={`/inbox/${groupId}`}
        replace={groupIdFromRoute ? true : false}
      >
        <Suspense
          fallback={
            <Skeleton
              variant="circle"
              animation="wave"
              width={34}
              height={34}
            />
          }
        >
          {isChannel ? (
            !!iconOverride?.[0] ? (
              <Avatar
                src={getImageUrl(iconOverride?.[0])}
                className={styles.userProfileItem}
              >
                <RemixIcon icon="megaphone-line" size={16} />
              </Avatar>
            ) : (
              <span className={styles.userProfileItem}>
                <RemixIcon icon="megaphone-line" size={16} />
              </span>
            )
          ) : isGroup ? (
            <div
              className={styles.userProfileItemStack}
              data-length={iconOverride?.length}
            >
              {iconOverride?.map((icon, index) =>
                icon ? (
                  <Avatar
                    key={icon + index}
                    src={getImageUrl(icon)}
                    className={styles.userProfileItem}
                  >
                    <RemixIcon
                      icon={
                        iconOverride.length === 1 ? 'group-line' : 'user-line'
                      }
                      size={16}
                    />
                  </Avatar>
                ) : (
                  <span className={styles.userProfileItem} key={icon + index}>
                    <RemixIcon
                      icon={
                        iconOverride.length === 1 ? 'group-line' : 'user-line'
                      }
                      size={16}
                    />
                  </span>
                )
              )}
            </div>
          ) : (
            <Suspense
              fallback={
                <Skeleton
                  variant="circle"
                  animation="wave"
                  width={34}
                  height={34}
                />
              }
            >
              <MessageUserLoader
                messageOwnerAccountId={messageOwnerAccountId}
                setUsername={setUsername}
              />
            </Suspense>
          )}
        </Suspense>
        <div className={styles.text}>
          <span>
            {isChannel && (
              <span>
                <RemixIcon icon="message-2-line" size={15} />
              </span>
            )}
            {title || username}{' '}
            {groupId !== ANNOUNCEMENTS_CHANNEL_ID && (
              <span>{isChannel ? `• ${totalMembersCount} members` : null}</span>
            )}
          </span>
          <span>{lastMessage || null}</span>
        </div>
        {isUnread && (
          <RemixIcon icon="circle-fill" size={6} color="var(--accentPrimary)" />
        )}
      </Link>
    </li>
  );
};

const MessageListItemMessageUserLoaderAccountQuery = graphql`
  query MessageListItemMessageUserLoaderAccountQuery($id: BigInt!) {
    accountsCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          identities {
            profilesCollection {
              edges {
                node {
                  id
                  nodeId
                  username
                }
              }
            }
          }
          ...UserProfileItemFragment
        }
      }
    }
  }
`;

export const MessageUserLoader = ({
  messageOwnerAccountId,
  setUsername,
}: {
  messageOwnerAccountId: string;
  setUsername?: (username: string) => void;
}) => {
  const {data: account} =
    useLazyLoadQuery<MessageListItemMessageUserLoaderAccountQueryType>(
      MessageListItemMessageUserLoaderAccountQuery,
      {
        id: messageOwnerAccountId,
      },
      {
        skip: !messageOwnerAccountId || messageOwnerAccountId === '',
      }
    );

  React.useEffect(() => {
    setUsername?.(
      account?.accountsCollection?.edges?.[0]?.node?.identities
        ?.profilesCollection?.edges?.[0]?.node?.username || ''
    );

    return () => {
      setUsername?.('');
    };
  }, [account, setUsername]);

  return (
    account?.accountsCollection?.edges?.[0]?.node && (
      <UserProfileItem
        hideText
        user={account.accountsCollection?.edges?.[0]?.node}
        className={styles.userProfileItem}
      />
    )
  );
};

export const CreateChannelTrigger = ({}: {}) => {
  return (
    <CreateChannelDialog
      trigger={
        <Button icon>
          <RemixIcon icon="add-fill" size={22} />
        </Button>
      }
    />
  );
};
