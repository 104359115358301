import React, {Suspense} from 'react';
import styles from '@/view/styles/components/Inbox/MyInbox.module.scss';
import DesktopTopBar from '../TopBar/DesktopTopBar';
import RemixIcon from '../RemixIcon';
import Button from '../Button';
import {
  commitLocalStoreUpdate,
  graphql,
  useLazyLoadQuery,
  useMutation,
  usePagination,
} from '@/kits/relay-kit/src';
import NotificationsItem from './Notifications/NotificationItem';
import EmptyProfileCard from '../EmptyProfileCards/EmptyProfileCard';
import MessageListItem from './Messages/MessageListItem';
import {useAccount} from '@/kits/account-kit/src';
import {useDarkMode} from '@/controller/hooks/darkMode';
import clsx from 'clsx';
import MessagesUserInfo from './Messages/MessagesUserInfo';
import Input from '../Input/Input';
import Menu from '../Menu';
const EmojiPickerComponent = dynamic(() => import('emoji-picker-react'), {
  ssr: false,
});
import {EmojiStyle, SuggestionMode, Theme} from 'emoji-picker-react';
import {emojiCategories} from '@/view/placeholders/placeholderData';
import dynamic from 'next/dynamic';
import InfiniteScrollContainer from '../InfiniteScrollContainer/InfiniteScrollContainer';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import insertMessageMutation from '@/graphql/insert-message';
import type {insertMessageMutation as InsertMessageMutationType} from '@/graphql/__generated__/insertMessageMutation.graphql';
import {generateFilter, subscribe} from '@/kits/supabase-ws-kit/src';
import Skeleton from '../Skeleton';
import InfiniteLoader from '../InfiniteLoader';
import {
  ANNOUNCEMENTS_CHANNEL_ID,
  GROUP_CHATS,
  MESSAGE_REACTIONS,
  REPORTS_CHANNEL_ID,
  TEAM_MEMBERS,
} from '@/config';
import TextClamp from '../TextClamp';
import {InviteToGroupDialog} from '../InviteToGroup/InviteToGroupDialog';
import updateAccountsGroupsMutation from '@/graphql/update-accounts-groups';
import type {updateAccountsGroupsMutation as UpdateAccountsGroupsMutationType} from '@/graphql/__generated__/updateAccountsGroupsMutation.graphql';
import {GroupSettingsDialog} from '../GroupSettings/GroupSettingsDialog';
import insertAccountsInGroupMutation from '@/graphql/insert-accounts-in-group';
import type {insertAccountsInGroupMutation as InsertAccountsInGroupMutationType} from '@/graphql/__generated__/insertAccountsInGroupMutation.graphql';
import {ToastActions} from '@/state/hooks/toasts';
import updateNotificationMutation from '@/graphql/update-notification';
import type {updateNotificationMutation as NotificationMutationType} from '@/graphql/__generated__/updateNotificationMutation.graphql';
import {NotificationsActions} from '@/state/hooks/notifications';
import Router from 'next/router';
import type {MyInboxContentNotificationsQuery as MyInboxContentNotificationsQueryType} from './__generated__/MyInboxContentNotificationsQuery.graphql';
import {MyInboxContentNotificationsPaginated$key} from './__generated__/MyInboxContentNotificationsPaginated.graphql';
import {MyInboxContentNotificationsPaginatedQuery} from './__generated__/MyInboxContentNotificationsPaginatedQuery.graphql';
import type {MyInboxContentGetGroupQuery as MyInboxContentGetGroupQueryType} from './__generated__/MyInboxContentGetGroupQuery.graphql';
import type {MyInboxContentGetGroupsQuery as MyInboxContentGetGroupsQueryType} from './__generated__/MyInboxContentGetGroupsQuery.graphql';
import type {MyInboxContentGetGroupMemberQuery as MyInboxContentGetGroupMemberQueryType} from './__generated__/MyInboxContentGetGroupMemberQuery.graphql';
import type {MyInboxContentGetMessagesQuery as MyInboxContentGetMessagesQueryType} from './__generated__/MyInboxContentGetMessagesQuery.graphql';
import {MyInboxContentGetMessagesPaginated$key} from './__generated__/MyInboxContentGetMessagesPaginated.graphql';
import {MyInboxContentGetMessagesPaginatedQuery} from './__generated__/MyInboxContentGetMessagesPaginatedQuery.graphql';
import type {MyInboxContentMyUsernameQuery as MyInboxContentMyUsernameQueryType} from './__generated__/MyInboxContentMyUsernameQuery.graphql';

const TIME_ELAPSED_THRESHOLD = 1000 * 60 * 60; // 1 hour

type Response = {
  data: {
    accounts_groupsCollection: {
      edges: {
        node: {
          id: string;
          nodeId: string;
        };
      }[];
    };
  };
};

const MyInboxContentGetGroupsQuery = graphql`
  query MyInboxContentGetGroupsQuery($accountId: BigInt!) {
    accounts_groupsCollection(filter: {account_id: {eq: $accountId}}) {
      edges {
        node {
          id
          nodeId
          group_id
          read
          write
          execute
          muted
          last_read_at
        }
      }
    }
  }
`;

const MyInboxContentGetGroupQuery = graphql`
  query MyInboxContentGetGroupQuery($groupId: BigInt!) {
    groupsCollection(filter: {id: {eq: $groupId}}) {
      edges {
        node {
          id
          nodeId
          title
          description
          is_channel
          is_private
        }
      }
    }
  }
`;

const MyInboxContentGetGroupMemberQuery = graphql`
  query MyInboxContentGetGroupMemberQuery(
    $accountId: BigInt!
    $groupId: BigInt!
  ) {
    accounts_groupsCollection(
      filter: {account_id: {eq: $accountId}, group_id: {eq: $groupId}}
    ) {
      edges {
        node {
          last_read_at
          read
          write
          execute
          muted
        }
      }
    }
  }
`;

export default function MyInboxContent({
  page,
  setPage,
  close,
  groupId,
}: {
  page: number;
  setPage: (page: number) => void;
  close: (link?: string) => void;
  groupId: string | null;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const [hasInit, setHasInit] = React.useState(false);

  React.useEffect(() => {
    setHasInit(false);
  }, [groupId]);

  const {isMobile} = useBreakpoint();

  const [replyToMessage, setReplyToMessage] = React.useState<
    | {
        id: string;
        nodeId: string;
        content: string;
        by: string;
      }
    | undefined
  >(undefined);

  const {retry: refetchGroups} =
    useLazyLoadQuery<MyInboxContentGetGroupsQueryType>(
      MyInboxContentGetGroupsQuery,
      {
        accountId: accountId || '',
      },
      {
        skip: !accountId || accountId === '',
      }
    );

  const {data: groupData} = useLazyLoadQuery<MyInboxContentGetGroupQueryType>(
    MyInboxContentGetGroupQuery,
    {
      groupId: groupId || '',
    },
    {
      skip: !groupId || groupId === '',
    }
  );

  const {data: myRole} =
    useLazyLoadQuery<MyInboxContentGetGroupMemberQueryType>(
      MyInboxContentGetGroupMemberQuery,
      {
        accountId: accountId || '',
        groupId: groupId || '',
      },
      {
        skip: !accountId || !groupId || accountId === '' || groupId === '',
        onResponse(response) {
          const res = response as Response;
          if (
            (groupId === REPORTS_CHANNEL_ID &&
              accountId &&
              TEAM_MEMBERS.includes(accountId)) ||
            (groupData?.groupsCollection?.edges?.[0]?.node?.is_channel &&
              groupData?.groupsCollection?.edges?.[0]?.node?.is_private ===
                false)
          ) {
            return;
          } else if (res.data.accounts_groupsCollection?.edges?.length === 0) {
            Router.push('/inbox');
          }
        },
      }
    );

  const amIaMember = myRole?.accounts_groupsCollection?.edges?.length !== 0;

  const channelAdmin = groupData?.groupsCollection?.edges?.[0]?.node?.is_channel
    ? myRole?.accounts_groupsCollection?.edges?.[0]?.node?.execute === true ||
      myRole?.accounts_groupsCollection?.edges?.[0]?.node?.write === true
    : true;

  const group = groupData?.groupsCollection?.edges?.[0]?.node;

  const [insertAccountsInGroup] =
    useMutation<InsertAccountsInGroupMutationType>(
      insertAccountsInGroupMutation
    );

  const [ownerUsername, setOwnerUsername] = React.useState<string | undefined>(
    undefined
  );

  return amIaMember ||
    (groupData?.groupsCollection?.edges?.[0]?.node?.is_channel &&
      groupData?.groupsCollection?.edges?.[0]?.node?.is_private === false) ||
    (groupId === REPORTS_CHANNEL_ID &&
      accountId &&
      TEAM_MEMBERS.includes(accountId)) ? (
    <div className={styles.content}>
      {!isMobile && (
        <DesktopTopBar
          title={
            page === 0 ? (
              'Inbox'
            ) : (
              <div className={styles.contentHeaderTitle}>
                <Suspense fallback={<span>Please wait...</span>}>
                  <MessagesUserInfo
                    groupId={groupId || ''}
                    view="header"
                    title={group?.title || ''}
                    description={group?.description || ''}
                  />
                </Suspense>
              </div>
            )
          }
          className={styles.contentHeader}
          leftActions={
            page === 0 ? [<div key={'spacer'} className={styles.spacer} />] : []
          }
          leftActionsOverride
          rightActions={
            page === 1
              ? GROUP_CHATS && groupId !== ANNOUNCEMENTS_CHANNEL_ID
                ? [
                    <GroupSettingsDialog
                      key={'settings'}
                      trigger={
                        <Button icon>
                          <RemixIcon icon="settings-3-line" size={24} />
                        </Button>
                      }
                      groupId={groupId || ''}
                    />,
                  ]
                : [<span key={'spacer'} />]
              : [<div key={'spacer'} className={styles.spacer} />]
          }
          rightActionsOverride
        />
      )}
      {page === 0 ? (
        <>
          <Suspense
            fallback={<InfiniteLoader loaderSize={48} height={'100%'} />}
          >
            <NotificationsWindowBody setPage={setPage} close={close} />
          </Suspense>
          {isMobile && (
            <div className={styles.mobileChatTopBar}>
              <Button icon onClick={() => setPage(0)}>
                <RemixIcon icon="arrow-left-s-line" size={24} />
              </Button>
              <span>Notifications</span>
            </div>
          )}
        </>
      ) : (
        groupId &&
        accountId && (
          <>
            <div className={clsx(styles.contentBody, styles.messageWindow)}>
              <div className={styles.messageWindowLeft}>
                {groupId !== REPORTS_CHANNEL_ID && (
                  <MessageInput
                    groupId={groupId}
                    replyToMessage={replyToMessage}
                    setReplyToMessage={setReplyToMessage}
                    channelAdmin={channelAdmin}
                  />
                )}
                <div
                  onLoad={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (hasInit) return;
                    e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
                    setTimeout(() => {
                      setHasInit(true);
                    }, 1000);
                  }}
                >
                  <Suspense fallback={<InfiniteLoader height={100} />}>
                    <MessagesUserInfo
                      groupId={groupId}
                      title={group?.title || ''}
                      description={group?.description || ''}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 'var(--padding-large, 16px)',
                            width: '100%',
                          }}
                        >
                          <Skeleton
                            variant="rect"
                            animation="wave"
                            height={48}
                            styles={{
                              maxWidth: '375px',
                              marginTop: 'var(--padding-very-large, 16px)',
                            }}
                          />
                          <Skeleton
                            variant="rect"
                            animation="wave"
                            height={48}
                            styles={{
                              maxWidth: '280px',
                            }}
                          />
                          <Skeleton
                            variant="rect"
                            animation="wave"
                            height={72}
                            styles={{
                              maxWidth: '375px',
                              float: 'right',
                              marginTop: 'var(--padding-very-large, 16px)',
                            }}
                          />
                          <Skeleton
                            variant="rect"
                            animation="wave"
                            height={48}
                            styles={{
                              maxWidth: '220px',
                              marginTop: 'var(--padding-very-large, 16px)',
                            }}
                          />
                        </div>
                      </div>
                    }
                  >
                    <MessageWindowBody
                      groupId={groupId}
                      hasInit={hasInit}
                      replyingToMessageId={replyToMessage?.id}
                      setReplyToMessage={setReplyToMessage}
                      isGroup={!group?.is_private || false}
                      isChannel={group?.is_channel || false}
                      channelAdmin={channelAdmin}
                      ownerUsername={ownerUsername}
                    />
                  </Suspense>
                </div>
              </div>
              {!isMobile && groupId !== REPORTS_CHANNEL_ID && (
                <div className={styles.messageWindowRight}>
                  <Suspense fallback={<InfiniteLoader height={'100%'} />}>
                    <MessagesUserInfo
                      setOwnerUsername={setOwnerUsername}
                      groupId={groupId}
                      view="sidebar"
                      title={group?.title || ''}
                      description={group?.description || ''}
                      refetchGroups={refetchGroups}
                    />
                  </Suspense>
                </div>
              )}
            </div>
            {isMobile && (
              <div className={styles.mobileChatTopBar}>
                <Button icon onClick={() => setPage(0)}>
                  <RemixIcon icon="arrow-left-s-line" size={24} />
                </Button>
                <Suspense fallback={<span>Please wait...</span>}>
                  <MessagesUserInfo
                    groupId={groupId}
                    view="header"
                    title={group?.title || ''}
                    description={group?.description || ''}
                  />
                </Suspense>
                {groupId !== ANNOUNCEMENTS_CHANNEL_ID && (
                  <GroupSettingsDialog
                    key={'settings'}
                    trigger={
                      <Button icon>
                        <RemixIcon icon="settings-3-line" size={24} />
                      </Button>
                    }
                    groupId={groupId || ''}
                  />
                )}
                {GROUP_CHATS && channelAdmin ? (
                  <InviteToGroupDialog
                    key={'invite'}
                    trigger={
                      <Button filled accent>
                        <RemixIcon icon="user-add-line" size={18} />
                        <span>Add</span>
                      </Button>
                    }
                    groupId={groupId || ''}
                  />
                ) : group?.is_channel &&
                  myRole?.accounts_groupsCollection?.edges?.length === 0 &&
                  groupId !== ANNOUNCEMENTS_CHANNEL_ID ? (
                  <div>
                    <Button
                      accent
                      onClick={() => {
                        insertAccountsInGroup({
                          variables: {
                            input: [
                              {
                                account_id: accountId,
                                group_id: groupId,
                                read: true,
                                write: false,
                                execute: false,
                                muted: false,
                                last_read_at: 'now',
                              },
                            ],
                          },
                          onCompleted() {
                            refetchGroups();
                          },
                          onError(error) {
                            ToastActions.addToast(
                              error.message || 'Error joining channel',
                              'Error joining channel',
                              '',
                              'failure'
                            );
                          },
                        });
                      }}
                    >
                      Join
                    </Button>
                  </div>
                ) : (
                  <span />
                )}
              </div>
            )}
          </>
        )
      )}
    </div>
  ) : (
    <div className={styles.center}>
      <EmptyProfileCard title={`This group does not exist`} small />
    </div>
  );
}

const MyInboxContentNotificationsQuery = graphql`
  query MyInboxContentNotificationsQuery($accountId: BigInt!) {
    ...MyInboxContentNotificationsPaginated @arguments(accountId: $accountId)
  }
`;

export const NotificationsWindowBody = ({
  setPage,
  close,
}: {
  setPage: (page: number) => void;
  close: (link?: string) => void;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const {data} = useLazyLoadQuery<MyInboxContentNotificationsQueryType>(
    MyInboxContentNotificationsQuery,
    {
      accountId: accountId || '',
    },
    {
      skip: !accountId || accountId === '',
    }
  );

  return (
    <div className={styles.contentBody}>
      {data ? (
        <PaginatedNotifications
          close={link => {
            setPage(1);
            close(link);
          }}
          notificationsKey={data}
        />
      ) : (
        <EmptyProfileCard
          icon={null}
          title="Your notifications will appear here"
          small
        />
      )}
    </div>
  );
};

const MyInboxContentNotificationsPaginated = graphql`
  fragment MyInboxContentNotificationsPaginated on Query
  @argumentDefinitions(
    accountId: {type: "BigInt!"}
    first: {type: "Int", defaultValue: 20}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "MyInboxContentNotificationsPaginatedQuery") {
    notificationsCollection(
      filter: {receiver_id: {eq: $accountId}}
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "MyInboxContentNotificationsPaginated_notificationsCollection"
      ) {
      totalCount
      edges {
        node {
          id
          nodeId
          type
          read
          ...NotificationItemFragment
        }
      }
    }
  }
`;

const PaginatedNotifications = ({
  close,
  notificationsKey,
}: {
  close: (link?: string) => void;
  notificationsKey: MyInboxContentNotificationsPaginated$key | null | undefined;
}) => {
  const {data, hasNext, loadNext, isLoadingNext, isLoading} = usePagination<
    MyInboxContentNotificationsPaginatedQuery,
    MyInboxContentNotificationsPaginated$key
  >(MyInboxContentNotificationsPaginated, notificationsKey);

  const [markAsRead] = useMutation<NotificationMutationType>(
    updateNotificationMutation
  );

  React.useEffect(() => {
    return () => {
      data?.notificationsCollection?.edges
        ?.filter(edge => edge.node.read === false)
        .forEach((edge, index) => {
          markAsRead({
            variables: {
              input: {
                read: true,
                updated_at: 'now',
              },
              filter: {
                id: {
                  eq: edge.node.id,
                },
                read: {
                  eq: false,
                },
              },
            },
          }).then(() => {
            commitLocalStoreUpdate(store => {
              const notificationToMarkAsRead = store.get(edge.node.nodeId);
              notificationToMarkAsRead?.setValue(true, 'read');
            });
          });
          if (
            data?.notificationsCollection?.edges &&
            index === data?.notificationsCollection?.edges?.length - 1
          ) {
            NotificationsActions.updateUnreadMessageCount(0);
          }
        });
    };
  }, [data?.notificationsCollection?.edges]);
  return (
    <ul className={styles.notifications}>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                hasNext && loadNext(10);
              }
            : undefined
        }
        styleOverrides={{
          minHeight: '100px',
        }}
      >
        {data?.notificationsCollection?.edges?.map(edge => {
          return (
            <NotificationsItem
              key={edge.node.id}
              itemKey={edge.node}
              close={close}
              view={'default'}
              isRead={edge.node.read}
            />
          );
        })}
      </InfiniteScrollContainer>
    </ul>
  );
};

const MyInboxContentGetMessagesQuery = graphql`
  query MyInboxContentGetMessagesQuery($groupId: BigInt!) {
    messagesCollection(
      filter: {group_id: {eq: $groupId}}
      orderBy: {created_at: DescNullsLast}
      first: 30
    ) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
    ...MyInboxContentGetMessagesPaginated @arguments(groupId: $groupId)
  }
`;

const MessageWindowBody = ({
  groupId,
  hasInit,
  replyingToMessageId,
  setReplyToMessage,
  isGroup = false,
  isChannel = false,
  channelAdmin = false,
  ownerUsername,
}: {
  groupId: string;
  hasInit: boolean;
  replyingToMessageId?: string;
  setReplyToMessage?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          nodeId: string;
          content: string;
          by: string;
        }
      | undefined
    >
  >;
  isGroup?: boolean;
  isChannel?: boolean;
  channelAdmin?: boolean;
  ownerUsername?: string;
}) => {
  const {data: initMessages, retry} =
    useLazyLoadQuery<MyInboxContentGetMessagesQueryType>(
      MyInboxContentGetMessagesQuery,
      {
        groupId: groupId,
      },
      {
        skip: !groupId || groupId === '',
      }
    );

  return (
    <div>
      <Suspense fallback={<InfiniteLoader />}>
        <PaginatedMessages
          messages={initMessages}
          groupId={groupId}
          hasInit={hasInit}
          retry={retry}
          replyingToMessageId={replyingToMessageId}
          setReplyToMessage={setReplyToMessage}
          isGroup={isGroup}
          isChannel={isChannel}
          channelAdmin={channelAdmin}
          ownerUsername={ownerUsername}
        />
      </Suspense>
      {initMessages?.messagesCollection?.edges?.length === 0 && (
        <EmptyProfileCard
          icon={null}
          title={
            isChannel
              ? 'No messages in this channel yet'
              : isGroup
              ? 'No messages in this group yet'
              : 'Start a conversation with this user'
          }
          small
        />
      )}
    </div>
  );
};

const MyInboxContentGetMessagesPaginated = graphql`
  fragment MyInboxContentGetMessagesPaginated on Query
  @argumentDefinitions(
    groupId: {type: "BigInt!"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "MyInboxContentGetMessagesPaginatedQuery") {
    messagesCollection(
      filter: {group_id: {eq: $groupId}}
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "MyInboxContentGetMessagesPaginated_messagesCollection"
      ) {
      edges {
        node {
          id
          nodeId
          content
          created_at
          account_id
          messages {
            id
            nodeId
            content
          }
          messages_reactionsCollection {
            edges {
              node {
                id
                nodeId
                reaction
                accounts {
                  id
                  identities {
                    id
                    nodeId
                    profilesCollection {
                      edges {
                        node {
                          id
                          nodeId
                          username
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MyInboxContentMyUsernameQuery = graphql`
  query MyInboxContentMyUsernameQuery($id: BigInt!) {
    profilesCollection(filter: {identity_id: {eq: $id}}) {
      edges {
        node {
          username
        }
      }
    }
  }
`;

const PaginatedMessages = ({
  messages: initMessages,
  groupId,
  hasInit,
  retry,
  replyingToMessageId,
  setReplyToMessage,
  isGroup = false,
  isChannel = false,
  channelAdmin = false,
  ownerUsername,
}: {
  messages: MyInboxContentGetMessagesPaginated$key | null | undefined;
  groupId: string;
  hasInit: boolean;
  retry: () => void;
  replyingToMessageId?: string;
  setReplyToMessage?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          nodeId: string;
          content: string;
          by: string;
        }
      | undefined
    >
  >;
  isGroup?: boolean;
  isChannel?: boolean;
  channelAdmin?: boolean;
  ownerUsername?: string;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user?.isLoggedIn ? user.accountId : undefined;
  const identityId = user?.isLoggedIn ? user.identityId : undefined;

  const {data: myUser} = useLazyLoadQuery<MyInboxContentMyUsernameQueryType>(
    MyInboxContentMyUsernameQuery,
    {
      id: identityId || '',
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  const {
    data,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
    error,
    errorNext,
    errorPrevious,
  } = usePagination<
    MyInboxContentGetMessagesPaginatedQuery,
    MyInboxContentGetMessagesPaginated$key
  >(MyInboxContentGetMessagesPaginated, initMessages);

  const [messages, setMessages] = React.useState<
    {
      id: string;
      nodeId: string;
      content: string;
      created_at: string;
      account_id: string;
      parent_message_id: string | null;
      parent_message_content: string | null;
    }[]
  >([]);

  const [isEndOfChat, setIsEndOfChat] = React.useState<boolean>(true);
  const [hasNewMessages, setHasNewMessages] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!groupId || !accountId) return;
    const channel = subscribe({
      channel: `channel:${groupId}`,
      event: '*',
      table: 'messages',
      filter: generateFilter({
        column_name: 'group_id',
        comparator: 'eq',
        value: groupId,
      }),
      callback(payload) {
        if (payload.eventType === 'INSERT') {
          const newMessage = payload.new;
          if (parseInt(newMessage.group_id) !== parseInt(groupId)) return;
          setMessages(prevMessages => {
            return [
              {
                id: newMessage.id,
                nodeId: newMessage.nodeId,
                content: newMessage.content,
                created_at: newMessage.created_at,
                account_id: newMessage.account_id,
                parent_message_id: newMessage.parent_message_id || null,
                parent_message_content: null,
              },
              ...(prevMessages || []),
            ];
          });
          if (
            !isEndOfChat &&
            parseInt(payload.new.account_id) !== parseInt(accountId || '0')
          ) {
            setHasNewMessages(true);
          }
        } else if (payload.eventType === 'DELETE') {
          setMessages(prevMessages => {
            return prevMessages?.filter(
              prevMessage => prevMessage.id !== payload.old.id
            );
          });
        }
      },
    });

    return () => {
      channel.unsubscribe();
      retry();
    };
  }, [groupId, accountId]);

  React.useEffect(() => {
    if (data?.messagesCollection?.edges) {
      setMessages(
        data.messagesCollection.edges.map(edge => {
          return {
            id: edge.node.id,
            nodeId: edge.node.nodeId,
            content: edge.node.content,
            created_at: edge.node.created_at,
            account_id: edge.node.account_id,
            parent_message_id: edge.node.messages?.id || null,
            parent_message_content: edge.node.messages?.content || null,
          };
        })
      );
    }
  }, [data?.messagesCollection?.edges]);

  const [updateAccountsGroups] = useMutation<UpdateAccountsGroupsMutationType>(
    updateAccountsGroupsMutation
  );

  const newMessageIndicatorRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries?.forEach(entry => {
          if (entry.isIntersecting) {
            setHasNewMessages(false);
            setIsEndOfChat(true);
            updateAccountsGroups({
              variables: {
                filter: {
                  account_id: {eq: accountId},
                  group_id: {eq: groupId},
                },
                input: {
                  last_read_at: 'now',
                },
              },
              updater(store) {
                const group = store
                  .getRootField('updateaccounts_groupsCollection')
                  ?.getLinkedRecords('records')?.[0];
                const groupNodeId = group?.getDataID();

                if (!group) return;

                store
                  .get(groupNodeId)
                  ?.setValue(group.getValue('last_read_at'), 'last_read_at');
              },
            });
          } else {
            setIsEndOfChat(false);
          }
        });
      },
      {
        threshold: 1,
      }
    );
    if (newMessageIndicatorRef.current) {
      observer.observe(newMessageIndicatorRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [groupId]);

  const [reactions, setReactions] = React.useState<
    {
      reaction: string;
      messageId: string;
      accountId: string;
      username: string;
    }[]
  >([]);

  React.useEffect(() => {
    if (!data?.messagesCollection?.edges) return;
    let reactions: {
      reaction: string;
      messageId: string;
      accountId: string;
      username: string;
    }[] = [];

    data?.messagesCollection?.edges?.forEach(edge => {
      edge.node.messages_reactionsCollection?.edges?.forEach(reactionEdge => {
        const reaction = {
          reaction: reactionEdge?.node.reaction || '',
          messageId: edge.node.id || '',
          accountId: reactionEdge?.node?.accounts?.id || '',
          username:
            reactionEdge?.node.accounts.identities.profilesCollection
              ?.edges?.[0]?.node?.username || '',
        };
        reactions.push(reaction);
      });
    });

    setReactions(reactions || []);
  }, [data?.messagesCollection?.edges]);

  return (
    <>
      {hasNewMessages && !isEndOfChat && (
        <div
          className={styles.newMessagesIndicator}
          onClick={() => {
            newMessageIndicatorRef.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          You have new messages
        </div>
      )}
      <div
        ref={newMessageIndicatorRef}
        style={{
          width: '100%',
          height: '1px',
        }}
      />
      {MESSAGE_REACTIONS && (
        <Suspense fallback={<></>}>
          <MessageReactionsWs
            groupId={groupId}
            messageIds={messages?.map(message => message.id) || []}
            addReaction={(reaction, messageId) => {
              setReactions(prevReactions => {
                return [
                  ...prevReactions,
                  {
                    reaction,
                    messageId,
                    accountId: accountId || '',
                    username:
                      myUser?.profilesCollection?.edges?.[0]?.node?.username ||
                      '',
                  },
                ];
              });
            }}
            removeReaction={(reaction, messageId) => {
              setReactions(prevReactions => {
                return prevReactions.filter(
                  prevReaction =>
                    prevReaction.reaction === reaction &&
                    prevReaction.messageId === messageId &&
                    prevReaction.accountId === accountId
                );
              });
            }}
          />
        </Suspense>
      )}
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading && hasInit
            ? () => {
                hasNext && loadNext(30);
              }
            : undefined
        }
      >
        {messages?.map((message, index) => {
          const hasTimeElapsedSinceLast =
            index !== messages.length - 1 &&
            new Date(message.created_at).getTime() -
              new Date(messages?.[index + 1]?.created_at).getTime() >
              TIME_ELAPSED_THRESHOLD;

          return (
            <div
              key={message.nodeId + index + message.account_id + '-wrapper'}
              className={styles.messageItemWrapper}
            >
              <MessageListItem
                key={message.nodeId + index + message.account_id}
                user={message.account_id}
                view="default"
                onClick={() => {}}
                messageNodeId={message.nodeId}
                messageId={message.id}
                message={message.content}
                createdAt={message.created_at}
                myUsername={
                  myUser?.profilesCollection?.edges?.[0]?.node?.username ||
                  undefined
                }
                isContinued={
                  messages?.[index + 1]?.account_id === message.account_id ||
                  messages?.[index - 1]?.account_id === message.account_id
                }
                isFirst={
                  messages?.[index + 1]?.account_id !== message.account_id
                }
                isLast={
                  messages?.[index - 1]?.account_id !== message.account_id
                }
                groupId={groupId}
                onDelete={() => {
                  setMessages(prevMessages => {
                    return prevMessages?.filter(
                      prevMessage => prevMessage.id !== message.id
                    );
                  });
                }}
                isAReplyTo={
                  message.parent_message_id
                    ? {
                        parent_message_id: message.parent_message_id,
                        child_message_id: message.id,
                        parentContent:
                          message.parent_message_content ||
                          messages?.find(
                            m => m.id === message.parent_message_id
                          )?.content ||
                          undefined,
                      }
                    : undefined
                }
                replyingTo={replyingToMessageId === message.id}
                setReplyToMessage={setReplyToMessage}
                reactions={
                  reactions?.filter(
                    reaction => reaction.messageId === message.id
                  ) || []
                }
                addReaction={(reaction, messageId) => {
                  setReactions(prevReactions => {
                    return [
                      ...prevReactions,
                      {
                        reaction,
                        messageId,
                        accountId: accountId || '',
                        username:
                          myUser?.profilesCollection?.edges?.[0]?.node
                            ?.username || '',
                      },
                    ];
                  });
                }}
                removeReaction={(reaction, messageId) => {
                  setReactions(prevReactions => {
                    return prevReactions.filter(
                      prevReaction =>
                        prevReaction.reaction === reaction &&
                        prevReaction.messageId === messageId
                    );
                  });
                }}
                isGroup={isGroup}
                isChannel={isChannel}
                channelAdmin={channelAdmin}
              />
              {hasTimeElapsedSinceLast && (
                <div
                  key={
                    message.nodeId +
                    index +
                    message.account_id +
                    '-elapsed_timestamp'
                  }
                  className={styles.timeElapsedIndicator}
                >
                  {new Date(message.created_at).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                    hour12: true,
                  })}
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScrollContainer>
      {!hasNext && !isLoadingNext && (isChannel || isGroup) && (
        <div className={styles.createdThisText}>
          <span>
            {ownerUsername} created this {isChannel ? 'channel' : 'group'}
          </span>
        </div>
      )}
    </>
  );
};

const MessageInput = ({
  groupId,
  replyToMessage,
  setReplyToMessage,
  channelAdmin,
}: {
  groupId: string;
  replyToMessage?: {
    id: string;
    nodeId: string;
    content: string;
    by: string;
  };
  setReplyToMessage: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          nodeId: string;
          content: string;
          by: string;
        }
      | undefined
    >
  >;
  channelAdmin: boolean;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const [message, setMessage] = React.useState('');
  const [showEmojiPicker, setShowEmojiPicker] = React.useState<boolean>(false);
  const {darkMode} = useDarkMode();

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    setMessage(inputRef.current?.value || '');
  }, [inputRef.current?.value]);

  React.useEffect(() => {
    if (replyToMessage && inputRef.current) {
      inputRef.current.focus();
    }
  }, [replyToMessage]);

  const [insertMessage, {loading: insertMessageLoading}] =
    useMutation<InsertMessageMutationType>(insertMessageMutation);

  if (!channelAdmin) {
    return (
      <div className={clsx(styles.inputContainer, styles.inputDisabled)}>
        <div>Only admins can send messages. You can read and react.</div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.inputContainer}>
        <div>
          <Button
            icon
            onClick={() => {
              setShowEmojiPicker(true);
            }}
            className={styles.emojiPickerToggle}
            tooltip="Emoji"
            tooltipSide="top"
          >
            <Menu
              side="top"
              open={showEmojiPicker}
              removePadding
              element={close => (
                <div className={styles.emojiPicker}>
                  <EmojiPickerComponent
                    theme={darkMode ? Theme.DARK : Theme.LIGHT || Theme.AUTO}
                    onEmojiClick={(emojiObject, e) => {
                      const emoji = emojiObject.emoji;
                      const cursorPosition = inputRef.current?.selectionStart;
                      if (cursorPosition) {
                        const newMessage =
                          message.slice(0, cursorPosition) +
                          emoji +
                          message.slice(cursorPosition);
                        setMessage(newMessage);
                        inputRef.current.value = newMessage;
                        inputRef.current.selectionStart = cursorPosition + 1;
                        inputRef.current.selectionEnd = cursorPosition + 1;
                      } else {
                        setMessage(message + emoji);
                        if (inputRef.current) {
                          inputRef.current.value = message + emoji;
                        }
                      }
                      // restore cursor position

                      // inputRef.current?.focus();
                      // close?.();
                    }}
                    lazyLoadEmojis
                    suggestedEmojisMode={SuggestionMode.FREQUENT}
                    height={400}
                    width={300}
                    previewConfig={{
                      showPreview: false,
                    }}
                    categories={emojiCategories}
                    searchDisabled={false}
                    autoFocusSearch={false}
                    emojiVersion={'12.1'}
                    skinTonesDisabled
                    emojiStyle={EmojiStyle.NATIVE}
                  />
                </div>
              )}
              arrow={false}
              className={styles.emojiPickerPopper}
            >
              <div>
                <RemixIcon icon="chat-smile-3-line" size={20} />
              </div>
            </Menu>
          </Button>
          <Input
            ref={inputRef}
            defaultValue={message}
            onChange={setMessage}
            type="text"
            fullWidth
            placeholder="Type a message"
            onEnter={() => {
              if (message.trim() === '') return;
              if (!groupId || !accountId) return;
              if (insertMessageLoading) return;
              insertMessage({
                variables: {
                  input: [
                    {
                      account_id: accountId,
                      content: message,
                      group_id: groupId,
                      parent_message_id: !!replyToMessage?.id
                        ? replyToMessage.id
                        : null,
                    },
                  ],
                },
                onCompleted(response) {
                  const newMessageId =
                    response.insertIntomessagesCollection?.records[0].id;
                  setMessage('');
                  setReplyToMessage(undefined);
                },
              });
            }}
          />
          <Button
            text
            accent
            disabled={
              message.trim() === '' ||
              insertMessageLoading ||
              !groupId ||
              !accountId
            }
            onClick={() => {
              if (message.trim() === '') return;
              if (!groupId || !accountId) return;
              if (insertMessageLoading) return;
              insertMessage({
                variables: {
                  input: [
                    {
                      account_id: accountId,
                      content: message,
                      group_id: groupId,
                      parent_message_id: !!replyToMessage?.id
                        ? replyToMessage.id
                        : null,
                    },
                  ],
                },
                onCompleted(response) {
                  const newMessageId =
                    response.insertIntomessagesCollection?.records[0].id;
                  setMessage('');
                  setReplyToMessage(undefined);
                },
              });
            }}
          >
            <span>Send</span>
          </Button>
        </div>
      </div>
      {replyToMessage && (
        <div className={styles.replyToContainer}>
          <RemixIcon icon="reply-fill" size={24} />
          <div>
            <span>{replyToMessage.by}</span>
            <TextClamp
              text={replyToMessage.content}
              maxChars={100}
              prefix={null}
            />
          </div>
          <Button
            icon
            onClick={() => {
              setReplyToMessage(undefined);
            }}
          >
            <RemixIcon icon="close-fill" size={24} />
          </Button>
        </div>
      )}
    </>
  );
};

const MessageReactionsWs = ({
  groupId,
  messageIds,
  addReaction,
  removeReaction,
}: {
  groupId: string;
  messageIds: string[];
  addReaction: (reaction: string, messageId: string) => void;
  removeReaction: (reaction: string, messageId: string) => void;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  React.useEffect(() => {
    if (!groupId || !accountId) return;
    const channel = subscribe({
      channel: `channel:${groupId}:reactions`,
      event: '*',
      table: 'messages_reactions',
      filter: generateFilter({
        column_name: 'message_id',
        comparator: 'in',
        value: messageIds || [],
      }),
      callback(payload) {
        if (payload.eventType === 'DELETE') {
          const oldReaction = payload.old;
          removeReaction(oldReaction.reaction, oldReaction.message_id);
          return;
        } else if (payload.eventType === 'INSERT') {
          const newReaction = payload.new;
          addReaction(newReaction.reaction, newReaction.message_id);
        }
      },
    });

    return () => {
      channel?.unsubscribe();
    };
  }, [groupId, messageIds]);

  return null;
};
